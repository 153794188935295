import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const OrderSetup = () => {
  const { t } = useTranslation()
  return <div className="order-setup">{t('dueLimitSol')}</div>
}

export default OrderSetup
