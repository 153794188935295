/* eslint-disable no-undef */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from 'components/common/Button'
import get from 'lodash/get'
import './style.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  formatAddress,
  formatNumberBro,
  getItemStorage,
  getLength,
  lowerCase,
  setItemStorage
} from 'common/functions'
import ReduxServices from 'common/redux'
import { useTranslation } from 'react-i18next'
import BaseAPI from 'controller/api/BaseAPI'
import { DeviceUUID } from 'device-uuid'
import StoreActions from 'controller/redux/actions/storeActions'
import { chainType, SOL_SCAN_URL } from 'common/constants'
import { KEY_STORE } from 'common/constants/keystore'
import BoxLockMatrix from '../BoxLockMatrix'
import BoxLockPassword from '../BoxLockPassword'
import images from 'assets/images'
import { Icon } from 'components/Icon'
import uniqBy from 'lodash/uniqBy'
import cn from 'clsx'
import useOnClickOutside from 'common/customHooks/useClickOutSide'
import Tooltip from 'components/Tooltip'

const ButtonConnected = () => {
  const { t } = useTranslation()
  const uuid = new DeviceUUID().get()
  const dispatch = useDispatch()
  const isConnected = useSelector((state) => state.isConnected)
  const accountSol = useSelector((state) => state.accountSol)
  const isConnectFirst = useSelector((state) => state.isConnectFirst)
  const isSession = useSelector((state) => state.isSession)
  const balanceSol = useSelector((state) => state.balanceSol)
  const walletRedux = useSelector((state) => state.walletRedux)
  // const listWalletSol =
  //   getLength(walletRedux) > 0
  //     ? walletRedux.filter((item) => item.chain === chainType.solana)
  //     : []
  const isMobile = window && window.innerWidth < 576

  const [isLoading, setIsLoading] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const pwTimer = useRef(null)
  const dropdownRef = useRef(null)

  const isNoWallet = !accountSol
  const isCoin98 = get(window, 'coin98.provider', false)

  const listWalletSol = useMemo(() => {
    if (getLength(walletRedux) > 0) {
      const listFilter = walletRedux.filter(
        (item) => item.chain === chainType.solana
      )
      return listFilter
    }
    return []
  }, [walletRedux])

  useOnClickOutside(dropdownRef, () => {
    setIsShowMenu(false)
  })

  useEffect(() => {
    onResetScroll()
  }, [isShowMenu])

  useEffect(() => {
    if (isMobile) {
      if (window.coin98) {
        if (isConnected) {
          loadInitialMobile()
        }
        window?.coin98?.sol.on('disconnect', () => {
          ReduxServices.resetRedux()
        })
      } else {
        ReduxServices.resetRedux()
      }
    } else {
      setIsLoading(true)
      setTimeout(() => {
        loadInitial()
      }, 0)
    }
  }, [])

  useEffect(() => {
    if (accountSol) {
      ReduxServices.fetchDataSol({ address: accountSol })
    }
  }, [accountSol])

  const disconnectWallet = () => {
    window.openModal({
      okText: t('disconnect'),
      title: t('disconnectWallet'),
      type: 'confirm',
      onCancel: () => {},
      onOk: async () => {
        await window?.coin98?.sol.disconnect()
        window.closeModal()
      }
    })
  }

  const loadInitialMobile = async () => {
    if (isCoin98) {
      setIsLoading(true)
      window?.coin98?.sol
        .request({ method: 'has_wallet', params: ['sol'] })
        .then(async () => {
          await ReduxServices.fetchDataSol({ isMobile: true })
          setIsLoading(false)
        })
        .catch((e) => {
          console.log('err connect', e)
          setIsLoading(false)
          ReduxServices.resetRedux()
        })
    } else {
      setIsLoading(false)
      ReduxServices.resetRedux()
      installWallet()
    }
  }

  // sync wallet

  useEffect(() => {
    if (!isMobile) {
      window?.coin98?.sol.on('syncWallet', (dataSync) => {
        setTimeout(() => {
          const walletRedux = get(dataSync, 'walletRedux')
          const typePassword = get(dataSync, 'typePassword')
          const session = get(dataSync, 'session')

          if (walletRedux) handleSetDataWallet(walletRedux)
          if (typePassword) {
            setItemStorage(typePassword, KEY_STORE.TYPE_PASSWORD)
          }
          if (session) {
            dispatch(StoreActions.setSession(get(session, 'isSession', false)))
          }
        }, 200)
      })
    }
  }, [])

  // useEffect(() => {
  //   if (!isMobile) {
  //     clearTimeout(pwTimer.current)
  //     pwTimer.current = setTimeout(() => {
  //       if (!isSession && !isNoWallet) {
  //         window.requestAnimationFrame(() => {
  //           openModalConfirmPass()
  //         })
  //       } else {
  //         if (typeof get(window, 'closeModalFirst') === 'function') {
  //           window && window.closeModalFirst()
  //         }
  //       }
  //     }, 1000)
  //   }
  // }, [isSession, isNoWallet])

  const openModalConfirmPass = () => {
    const typePassword = getItemStorage(KEY_STORE.TYPE_PASSWORD)
    const isMatrix = typePassword === 'matrix'
    if (window && typePassword) {
      window.openModalFirst({
        type: 'none',
        className: isMatrix ? 'popup-matrix' : '',
        content: isMatrix ? <BoxLockMatrix /> : <BoxLockPassword />,
        preventClose: true,
        noIcon: true,
        isConfirmPassWord: true
      })
    }
  }

  const installWallet = () => {
    window.openModal({
      title: t('coin98NotInstall'),
      content: t('installCoin98'),
      type: 'warning',
      onCancel: () => {},
      onOk: async () => {
        window.open(
          'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg'
        )
        window.closeModal()
      }
    })
  }

  const loadInitial = async () => {
    if (isCoin98) {
      // check connect first
      if (!isConnectFirst) {
        await BaseAPI.postData('connect', { id: uuid })
      }
      // connect_coin98
      try {
        const resConnect = await window.coin98?.sol.request({
          method: 'connect_coin98',
          params: { txtConnect: 'autoConnect', uuid }
        })

        if (resConnect && resConnect !== 'noConnectWallet') {
          dispatch(StoreActions.setDeviceId(resConnect))
          dispatch(StoreActions.setIsConnectFirst(true))
          dispatch(StoreActions.setConnected(true))
          syncWallet()
          setIsLoading(false)
        } else {
          dispatch(StoreActions.setWalletActive({}))
          ReduxServices.resetRedux()
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        ReduxServices.resetRedux()
        dispatch(StoreActions.setWalletActive({}))
      }
    } else {
      setIsLoading(false)
      ReduxServices.resetRedux()
      dispatch(StoreActions.setWalletActive({}))
    }
  }

  const syncWallet = async () => {
    const walletList = await window?.coin98?.sol.request({
      method: 'sync_wallet'
    })

    const listWalletAddress = uniqBy(walletList, (item) => {
      return `${item.address}`
    })

    dispatch(StoreActions.setWalletRedux(listWalletAddress))

    const typePassword = await window?.coin98?.sol.request({
      method: 'get_type_password'
    })
    const session = await window?.coin98?.sol.request({
      method: 'get_session'
    })

    handleSetDataWallet(walletList)
    dispatch(StoreActions.setSession(session))
    setItemStorage(typePassword, KEY_STORE.TYPE_PASSWORD)
  }

  const handleSetDataWallet = (walletList) => {
    let newWalletActive = walletList.find(
      (item) => item.chain === chainType.solana && item.isActive
    )

    if (!newWalletActive) {
      newWalletActive = walletList.find(
        (item) => item.chain === chainType.solana
      )
    }

    dispatch(StoreActions.setWalletActive(newWalletActive || {}))
    dispatch(StoreActions.setAccountSol(get(newWalletActive, 'address', '')))
    dispatch(StoreActions.setWalletRedux(walletList))

    // if (getLength(arrWallet) === 0) {
    //   setTimeout(() => {
    //     if (!window.coin98 && window.coin98.sol.isConnected()) {
    //       setIsConnectedHeader(false)
    //     } else {
    //       window.closeModal()
    //       window.openWalletNotFound()
    //     }
    //   }, 1500)
    // }
  }

  const handleConnect = () => {
    if (!isCoin98) {
      installWallet()
    } else {
      if (isMobile) {
        loadInitialMobile()
      } else {
        loadInitial()
      }
    }
  }

  const onResetScroll = () => {
    const listWalletEl = document.getElementById('wallet-dropdown-scroll')
    if (listWalletEl) {
      listWalletEl.scrollTop = 0
    }
  }

  const onToggleAddressMenu = () => {
    setIsShowMenu(true)
  }

  const handleViewTxs = (address) => (e) => {
    e.stopPropagation()
    const urlExplorer = SOL_SCAN_URL.mainnet
    window.open(`${urlExplorer}/account/${address}`)
  }

  const handleHoverCopy = (bool) => () => {
    setIsHover(bool)
    if (!bool) {
      setTimeout(() => {
        setIsCopying(false)
      }, 300)
    }
  }

  const handleCopyAddress = (address) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsCopying(true)
    window.navigator.clipboard.writeText(address)
  }

  const onSelectWallet = (name) => async () => {
    const walletItem = walletRedux.find(
      (it) => it.name === name && it.chain === chainType.solana
    )
    const newAccountSol = get(walletItem, 'address')
    const params = {
      data: walletItem,
      type: 'oneItem'
    }
    window.coin98?.provider
      .request({
        method: 'active_wallet',
        params
      })
      .then((res) => {
        dispatch(StoreActions.setWalletActive(walletItem || {}))
        dispatch(StoreActions.setAccountSol(newAccountSol))
      })
    setIsShowMenu(false)
  }

  return (
    <div className="button-connected-wr">
      {isNoWallet
        ? (
          <Button onClick={handleConnect} className="actions-connect">
            {t('noWallet')}
          </Button>
        )
        : isConnected
          ? (
            <div className="button-connect-container" ref={dropdownRef}>
              <Tooltip disabled={isMobile} content={t('changeWallet')}>
                <div className="button-connect" onClick={onToggleAddressMenu}>
                  <div className="button-connect__circle">
                    <img src={images.iconToken} alt="" className="icon-connect" />
                  </div>
                  <div className="button-connect__info">
                    <span>
                      {formatNumberBro({ number: balanceSol, mantissa: 4 })} SOL{' '}
                    </span>
                    <span className="text-address">
                      {formatAddress(accountSol)}
                    </span>
                  </div>

                  {isMobile && (
                    <div className="button-connect__dropdown">
                      <Button
                        className="actions-disconnect"
                        onClick={disconnectWallet}
                        isFullWidth
                      >
                        {t('disconnect')}
                      </Button>
                    </div>
                  )}
                </div>
              </Tooltip>
              {!isMobile && getLength(listWalletSol) > 0 && (
                <div
                  id="wallet-dropdown-scroll"
                  className={cn('wallet-dropdown', !isShowMenu && 'd-none')}
                >
                  {listWalletSol?.map((item) => {
                    const isActiveWallet =
                  lowerCase(item.address) === lowerCase(accountSol)
                    return (
                      <div
                        key={item.address}
                        className={cn(
                          'wallet-dropdown__item',
                          isActiveWallet && 'wallet-dropdown__item--active'
                        )}
                        onClick={onSelectWallet(item.name)}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <Icon
                              name="web_solana"
                              className={cn(
                                isActiveWallet && 'color-theme',
                                'text-l'
                              )}
                            />
                            <div className="ml-2">
                              <div className="text-sm">{get(item, 'name')}</div>
                              <div className="text-xs color-grey">
                                {formatAddress(get(item, 'address'), 5)}
                              </div>
                            </div>
                          </div>

                          <Icon
                            name="web_icon-check-done"
                            className="text-l icon-checked color-theme"
                          />
                        </div>

                        <div
                          onMouseEnter={handleHoverCopy(true)}
                          onMouseLeave={handleHoverCopy(false)}
                          className={cn(
                            isActiveWallet
                              ? 'd-flex mt-2 justify-content-between align-items-center'
                              : 'd-none'
                          )}
                        >
                          <div
                            className="actions"
                            onClick={handleCopyAddress(item.address)}
                          >
                            <Icon
                              name={
                                isCopying ? 'web_icon-check-done' : 'web_copy_down'
                              }
                              className="text-l"
                            />
                            <span className="ml-2 text-sm color-grey">
                              {t(isCopying ? 'copied' : 'copyAddress')}
                            </span>
                          </div>
                          <div
                            className="actions"
                            onClick={handleViewTxs(item.address)}
                          >
                            <Icon name="web_explorer" className="text-l" />
                            <span className="ml-2 text-sm color-grey">
                              {t('viewExplorer')}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
          : (
            <Button
              onClick={loadInitial}
              className="actions-connect"
              isLoading={isLoading}
            >
              {t('connectWallet')}
            </Button>
          )}
    </div>
  )
}

export default ButtonConnected
