import SliderComponent from 'components/Slider'
import Button from 'components/common/Button'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './style.module.scss'
import {
  formatNumberBro,
  formatAmountInput,
  roundingNumber,
  convertBalanceToWei,
  upperCase,
  formatPrice
} from 'common/functions'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'
import useRateFee from 'hooks/swap/useRateFee'
import { genConnectionSolana, genOwnerSolana } from 'common/solana'
import { useSelector } from 'react-redux'
import { SarosFarmService } from 'common/farm/SarosFarmService'
import { PublicKey } from '@solana/web3.js'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import BN from 'bn.js'
import { SAROS_FARM_ADDRESS } from 'common/pool/constants'
import useStatusTxsHash from 'hooks/useStatusTxsHash'
import BaseAPI from 'controller/api/BaseAPI'
import { Icon } from 'components/Icon'

const StakeModal = ({
  isUnStake,
  onCancel,
  dataFarm,
  balance,
  type,
  initData,
  setLoading
}) => {
  const { feeFull } = useRateFee()
  const statusHash = useStatusTxsHash()
  const { t } = useTranslation()
  const accountSol = useSelector((state) => state.accountSol)

  const [sliderValue, setSliderValue] = useState(0)
  const [amount, setAmount] = useState('')
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const isStakeScreen = get(dataFarm, 'isStakeScreen')
  const priceLp = get(dataFarm, 'priceLp', 0)
  const amountUSD = amount ? parseFloat(amount) * parseFloat(priceLp) : 0
  const isCheckAprByAmount = get(dataFarm, 'isCheckAprByAmount')

  const handleChangeSliderValue = (value) => {
    const amount = (parseInt(value) / 100) * parseFloat(balance)
    onChangeInput(amount)
    setSliderValue(value)
  }

  const handleMax = () => {
    handleChangeSliderValue(100)
  }

  const onChangeInput = (amount) => {
    const newValue = formatAmountInput(amount)
    if (!newValue) {
      setAmount('')
      setSliderValue('')
    }
    const slide = (newValue / balance) * 100
    setSliderValue(slide < 100 ? Math.floor(slide) : 100)
    setAmount(newValue)
  }

  const handleActions = async () => {
    let isMaxBalance = false
    setLoading(true)
    setIsLoadingButton(true)
    const connection = genConnectionSolana()
    const owner = await genOwnerSolana(accountSol)
    const rewards = get(dataFarm, 'rewards', [])
    const decimalTokenStake = get(dataFarm, 'decimalTokenStake')
    const amountWei = convertBalanceToWei(amount, decimalTokenStake)
    const poolAddress = new PublicKey(get(dataFarm, 'poolAddress'))
    const lpMint = new PublicKey(get(dataFarm, 'dataPoolInfo.mint'))
    const lpAccount = await TokenProgramService.findAssociatedTokenAddress(
      owner.publicKey,
      lpMint
    )
    const actionsStake = isUnStake ? 'unstakePool' : 'stakePool'
    if (parseFloat(amount) >= parseFloat(balance)) {
      isMaxBalance = true
    }
    const response = await SarosFarmService[actionsStake](
      connection,
      owner,
      poolAddress,
      lpAccount,
      new BN(amountWei),
      new PublicKey(SAROS_FARM_ADDRESS),
      rewards,
      isMaxBalance,
      lpMint,
      (hash) => {
        statusHash.handleTxsSuccess({
          message: t('pleaseWaitOrder'),
          callBack: handleWaitSuccess(hash, amount)
        })
      },
      (hash) => {
        onTradeSuccess(isUnStake ? 'unStakeSuccess' : 'stakeSuccess', hash)
      }
    )
    const { isErr, data } = response
    if (isErr) {
      window.closeModal()
      statusHash.handleTxsFailed({ message: t(data) })
      setLoading(false)
      setIsLoadingButton(false)
    }
  }

  const handleWaitSuccess = (hash, amount) => {
    statusHash.handleTxsSuccess({
      message: t('pleaseWaitOrder')
    })
    handlePostData({
      hash,
      walletAddress: accountSol,
      type,
      amount,
      poolAddress: get(dataFarm, 'poolAddress'),
      lpAddress: get(dataFarm, 'lpInfo.address')
    })
    window.closeModal()
  }

  const onTradeSuccess = async (textMess, hash) => {
    window.closeModal()
    statusHash.handleTxsSuccess({
      message: t(textMess)
    })
    statusHash.viewCompleteHash({ hash })

    const decimalTokenStake = get(dataFarm, 'decimalTokenStake', 9)

    const amountWei = parseFloat(
      convertBalanceToWei(amount, decimalTokenStake)
    )
    let userInfo = {
      ...dataFarm.userInfo
    }
    const balance = isUnStake
      ? parseFloat(userInfo.balance) + amountWei
      : parseFloat(userInfo.balance) - amountWei

    const amountUserStaked = isUnStake
      ? parseFloat(userInfo.amountUserStaked) - amountWei
      : parseFloat(userInfo.amountUserStaked) + amountWei

    userInfo = {
      ...userInfo,
      balance,
      amountUserStaked
    }
    initData({
      dataFarm: {
        ...dataFarm,
        userInfo
      },
      type
    })
    setLoading(false)
    setIsLoadingButton(false)
  }

  const handlePostData = async ({
    hash,
    walletAddress,
    type,
    amount,
    farmId,
    lpAddress,
    poolAddress,
    harvestData = []
  }) => {
    const data = {
      poolAddress,
      hash,
      createdAddress: walletAddress,
      type,
      amount,
      farmId,
      lpAddress,
      harvestData
    }
    if (isStakeScreen) {
      return await BaseAPI.postData('saros/stake/history', data)
    }
    await BaseAPI.postData('saros/farm/history', data)
  }

  const typeButton = useMemo(() => {
    const typeButton = {
      isDisabled: false,
      text: isUnStake ? 'unStake' : 'stakeNow'
    }
    if (!amount || parseFloat(amount) <= 0) {
      return {
        isDisabled: true,
        text: 'inputAmount'
      }
    }
    if (parseFloat(amount) > parseFloat(balance)) {
      return {
        isDisabled: true,
        text: 'tradeErrFund'
      }
    }
    return typeButton
  }, [amount, isUnStake, balance])

  const renderBalance = useMemo(() => {
    if (isStakeScreen) {
      const stakingToken = get(dataFarm, 'stakingToken')
      return `${upperCase(get(stakingToken, 'symbol'))} `
    }
    const token0 = get(dataFarm, 'token0')
    const token1 = get(dataFarm, 'token1')
    return `${upperCase(get(token0, 'symbol'))} - ${upperCase(
      get(token1, 'symbol')
    )} LP`
  }, [dataFarm])

  return (
    <div>
      <div className={styles.stakeContainer}>
        <div className="modal-top">
          <Icon type="lg" name={isUnStake ? 'saros_unstake' : 'saros_stake'} />
          {/* <img
            className="logo-stake"
            src={isUnStake ? images.logoUnstake : images.logoStake}
            alt=""
          /> */}
          <div className="modal-label text-xl semibold">
            {t(isUnStake ? 'unStakeLP' : 'stakeLP', {
              token: isStakeScreen ? '' : 'LP'
            })}
          </div>
        </div>

        <div className="slider-box">
          <div className="d-flex justify-content-between align-items-center">
            <NumberFormat
              placeholder="0.0"
              onValueChange={(event) => onChangeInput(event.value)}
              thousandsGroupStyle="thousand"
              value={amount ? roundingNumber(amount, 6, true) : ''}
              prefix=""
              decimalSeparator="."
              displayType="input"
              type="text"
              thousandSeparator={true}
              className="text-l color-grey semibold"
            />
            <div className="text-l semibold">{renderBalance}</div>
          </div>

          <div
            className={`d-flex ${
              !isCheckAprByAmount
                ? 'justify-content-between'
                : 'justify-content-end'
            } align-items-center mt-2`}
          >
            {!isCheckAprByAmount && (
              <div className="text-xs color-grey">
                {t('amount')}:{' '}
                <span className="color-normal">{formatPrice(amountUSD)}</span>
              </div>
            )}

            <div className="text-xs color-normal">
              {t('balance')}:{' '}
              <span onClick={handleMax} className="color-theme cursor-pointer">
                {formatNumberBro({ number: balance })}
              </span>
            </div>
          </div>
          <SliderComponent
            tipFormatter={(value) => <div>{`${value}%`}</div>}
            className="slider"
            marks={{
              0: '',
              25: '',
              50: '',
              75: '',
              100: ''
            }}
            value={sliderValue}
            onChange={handleChangeSliderValue}
          />
        </div>

        <div className="fee-est d-flex justify-content-between align-items-center mt-3 mb-3">
          <div className="text-sm color-grey">{t('estFee')}</div>
          <div className="text-sm color-normal">{feeFull}</div>
        </div>

        <div className="d-flex">
          <Button
            onClick={onCancel}
            type="grey"
            className="save-btn mr-2"
            isFullWidth
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleActions}
            isDisable={get(typeButton, 'isDisabled')}
            isLoading={isLoadingButton}
            className="save-btn ml-2"
            isFullWidth
          >
            {t(get(typeButton, 'text'))}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StakeModal
