/* eslint-disable no-var */
import React, { useContext, useEffect, useState } from 'react'
import {
  Switch,
  Route,
  withRouter,
  useHistory,
  useLocation
} from 'react-router-dom'
import ReduxServices from 'common/redux'
import Header from 'components/Header'
import get from 'lodash/get'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import ModalServices from 'containers/app/ModalServices'
import ModalServicesFirst from 'components/modals/ModalServicesFirst'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import PuffLoading from 'components/common/PuffLoading'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/global.scss'
import './App.scss'
import images from 'assets/images'
import 'rc-slider/assets/index.css'
import NotfoundScreen from 'pages/NotfoundScreen'
import Footer from 'components/Footer'
import NewPoolInfoToken from 'pages/NewInfoPool/Components/NewPoolInfoToken'
import NewInfoPool from 'pages/NewInfoPool'
import SnapshotScreen from 'pages/SnapshotScreen'
import SnapshotDetailScreen from 'pages/SnapshotDetailScreen'
import NewLandingScreen from 'pages/NewLandingScreen'
import NewLiquidityScreenContent from 'pages/NewLiquidityScreen/NewLiquidityScreenContent'
import NewFarmScreenContent from 'pages/NewFarmScreen/NewFarmScreenContent'
import NewPoolInfoContent from 'pages/NewInfoPool/Components/NewPoolInfoDetail/NewPoolInfoContent'
import NewSwapContent from 'pages/NewSwapScreen/NewSwapContent'
import { AppContext } from 'context/appContext'
import ListTokenMobileContainer from 'components/ListTokenMobileContainer'

let analytics
function App () {
  const history = useHistory()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  const appContext = useContext(AppContext)
  const { isLoadingDataAccountOwner, isDarkMode } = appContext

  const [scrollEvent, setScrollEvent] = useState({
    direction: 'down',
    key: Math.random()
  })

  const isLaunchPad = get(history, 'location.pathname', '').includes(
    'launchpad'
  )

  const isLaunchPadDetail = get(history, 'location.pathname', '').includes(
    'launchpad/'
  )

  const isLaunchPadAll = get(history, 'location.pathname', '').includes(
    'launchpad/all'
  )

  history.listen((location, action) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  })

  useEffect(() => {
    initFirebase()
    // initOneSignal()
    ReduxServices.refreshAllData()
      .then((res) => {
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    ReduxServices.activeSocket()
  }, [])

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', {
        page_location: document.location.href,
        page_path: location.pathname,
        page_title: `${location.pathname} - ${document.title}`
      })
      logEvent(analytics, 'screen_view', {
        firebase_screen: location.pathname
      })
    }
  }, [analytics, location.pathname])

  useEffect(() => {
    if (isDarkMode) {
      document.querySelector('body').style = 'background-color: #0f0f0f'
    } else {
      document.querySelector('body').style = 'background-color: #f5f5f5'
    }
  }, [isDarkMode])

  const initFirebase = () => {
    try {
      const firebaseConfig = {
        apiKey: 'AIzaSyDtv8xBfPWr9HGNM4lnUUMAm1F2OEwMPr8',
        authDomain: 'coin98-web-saros.firebaseapp.com',
        projectId: 'coin98-web-saros',
        storageBucket: 'coin98-web-saros.appspot.com',
        messagingSenderId: '1071399495144',
        appId: '1:1071399495144:web:14abc6d803575bc36bf05d',
        measurementId: 'G-BMVNWFJYJH'
      }

      const app = initializeApp(firebaseConfig)
      analytics = getAnalytics(app)
    } catch (err) {
      console.log('firebase', err)
    }
  }

  const initOneSignal = () => {
    var OneSignal = window.OneSignal || []
    OneSignal.push(function () {
      OneSignal.init({
        appId: 'b4c72824-b614-4a0b-a1a3-ffc523f4c0c0',
        allowLocalhostAsSecureOrigin: true,
        restrictedOriginEnabled: false,
        autoRegister: true,
        notifyButton: {
          enable: false
        }
      })
    })
    ReduxServices.refreshNotification()
  }

  if (isLoading || isLoadingDataAccountOwner) {
    return <PuffLoading isFullScreen />
  }

  const getEventScroll = (direction) => {
    setScrollEvent({
      direction,
      key: Math.random()
    })
  }
  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <Header scrollEvent={scrollEvent} />
      <div className="app-container">
        <Switch>
          <Route exact path="/">
            <NewLandingScreen getEventScroll={getEventScroll} />
          </Route>

          <Route path="/swap">
            <NewSwapContent />
          </Route>
          <Route path={['/farm', '/stake']}>
            <NewFarmScreenContent />
          </Route>
          <Route exact path={['/liquidity', '/liquidity/:typeLiquidity']}>
            <NewLiquidityScreenContent />
          </Route>
          <Route exact path={['/info', '/info/:type', '/info/:type/:address']}>
            <NewInfoPool />
          </Route>
          <Route exact path="/pool/:address">
            <NewPoolInfoContent />
          </Route>
          <Route exact path="/token/:address">
            <NewPoolInfoToken />
          </Route>
          <Route exact path="/snapshot/:id">
            <SnapshotDetailScreen />
          </Route>
          <Route exact path="/snapshot">
            <SnapshotScreen />
          </Route>

          <Route exact path="/not-found">
            <NotfoundScreen />
          </Route>
          {/* <Route exact path="/test-farm">
            <TestFarm />
          </Route> */}

          <Route path="*" exact={true} component={NotfoundScreen} />
        </Switch>
      </div>
      <Footer />
      <ModalServicesFirst />
      <ListTokenMobileContainer />
      <ModalServices />
      <ToastContainer position="bottom-right" />
    </div>
  )
}

export default withRouter(App)
