import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useCheckWallet = () => {
  const { t } = useTranslation()
  const [isConnectWallet, setIsConnectWallet] = useState(false)

  useEffect(() => {
    const isConnectWallet = get(window, 'coin98.provider.isCoin98', false)
    setIsConnectWallet(isConnectWallet)
  }, [])

  const handleInstallWallet = () => {
    window.openModal({
      title: t('coin98NotInstall'),
      content: t('installCoin98'),
      type: 'warning',
      onCancel: () => {},
      onOk: async () => {
        window.open(
          'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg'
        )
        window.closeModal()
      }
    })
  }

  return { handleInstallWallet, isConnectWallet }
}

export default useCheckWallet
