import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'
import SwapChart from './components/SwapChart'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import {
  convertBalanceToWei,
  convertWeiToBalance,
  formatNumberBro,
  getItemStorage,
  getLength,
  isProduction,
  roundingNumber,
  upperCase
} from 'common/functions'
import CompareSwap from './components/CompareSwap'
import InputTokenAmount from 'components/InputTokenAmount'
import SettingsController from 'components/SettingsController'
import SlippageModal from './components/SlippageModal'
import ReviewSwapModal from './components/ReviewSwapModal'
import useSarosSwap from 'hooks/swap/useSarosSwap'
import { chainType, listStableCoin, sarosSwapPoolType } from 'common/constants'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import useRateFee from 'hooks/swap/useRateFee'
import BaseAPI from 'controller/api/BaseAPI'
import Tooltip from 'components/Tooltip'
import {
  genConnectionSolana,
  genOwnerSolana,
  NATIVE_SOL,
  postBaseSendTxsNew
} from 'common/solana'
import { useDispatch, useSelector } from 'react-redux'
import BN from 'bn.js'
import { PublicKey } from '@solana/web3.js'
import { AggregatorService } from 'common/swap/aggregator'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import useStatusTxsHash from 'hooks/useStatusTxsHash'
import { SwapContext } from './context/SwapContext'
import { AppContext } from 'context/appContext'
import cloneDeep from 'lodash/cloneDeep'
import { SarosSwapService } from 'common/pool/saros_swap/sarosSwapServices'
import OrderSetup from 'pages/SwapRaydiumScreen/component/OrderSetup'
import ShareModal from 'pages/NewFarmScreen/Components/Modals/ShareModal'
import SwapRecent from './components/SwapRecent'
import StoreActions from 'controller/redux/actions/storeActions'
import useEffectCustom from 'hooks/useEffectCustom'
import BaseAdapter from 'controller/api/BaseAdapter'
import { compact, uniqBy } from 'lodash'
import useOnClickOutside from 'common/customHooks/useClickOutSide'
import {
  BURNER_ADDRESS,
  CHAIN_LINK_PROGRAM,
  CusdFactoryService,
  CUSD_MINT,
  CUSD_PROGRAM_ID,
  MINTER_ADDRESS
} from 'common/cusd/cusdServices'
import { store } from 'controller/redux/store/configureStore'

const usdcMint = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
const c98Mint = 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9'
const cusdMint = 'CUSDvqAQLbt7fRofcmV2EXfPA2t36kzj7FjzdmqDiNQL'

const NewSwapScreen = ({ shortcut0Mint, shortcut1Mint }) => {
  const { t } = useTranslation()
  const appContext = useContext(AppContext)
  const swapContext = useContext(SwapContext)
  const {
    listPoolNew,
    setSwapRouterData,
    setIsLoadingSwapRouter,
    isShortcut,
    selectedRecentSwap,
    isBurnOrMintCUSD
  } = swapContext

  const { listAccountOwner, handleGetAllAccountOwner } = appContext

  const accountSol = useSelector((state) => state.accountSol)
  const balanceSol = useSelector((state) => state.balanceSol)
  const inputBaseRef = useRef()

  useOnClickOutside(inputBaseRef, () => {
    setIsFocusBaseInput(false)
  })

  const {
    handlePushStateUrl,
    findPoolLiquidity,
    calculateUiFee,
    getListTokenSwap,
    updateRecentSwapList,
    getFeeMintBurnServices
  } = useSarosSwap({ isFetchPool: true })

  const { viewCompleteHash, handleTxsFailed, handleTxsSuccess } =
    useStatusTxsHash()
  const { feeFull, numFee } = useRateFee()
  const [selectedLeftComponent, setSelectedLeftComponent] = useState('table')
  const [isShowSwapChart, setIsShowSwapChart] = useState(false)
  const [swapRate, setSwapRate] = useState({}) // {base, pair}
  const [priceImpact, setPriceImpact] = useState({}) // {base, pair}
  const [isNotFoundPool, setIsNotFoundPool] = useState(false)

  const [smartRouter, setSmartRouter] = useState()
  const [isSwapping, setIsSwapping] = useState()

  const [mintToken, setMintToken] = useState({
    from: null,
    to: null
  })
  const [slippage, setSlippage] = useState(0.5)
  const [fromCoinInput, setFromCoinInput] = useState('')
  const [toCoinInput, setToCoinInput] = useState('')
  const [isBase, setIsBase] = useState(true)
  const [uiFee, setUiFee] = useState('')
  const [balanceToken, setBalanceToken] = useState({
    from: 0,
    to: 0
  })
  const [tokenList, setTokenList] = useState(
    getItemStorage('TOKENS_LIST') || []
  )

  const defaultSwapRouter = useRef({})

  const [isFocusBaseInput, setIsFocusBaseInput] = useState(false)
  const [cusdFee, setCusdFee] = useState(null)

  const fromToken = get(mintToken, `${isBase ? 'from' : 'to'}`)
  const toToken = get(mintToken, `${isBase ? 'to' : 'from'}`)
  const fromBalance = get(balanceToken, `${isBase ? 'from' : 'to'}`)
  const toBalance = get(balanceToken, `${isBase ? 'to' : 'from'}`)
  const calculateRate = get(swapRate, `${isBase ? 'base' : 'pair'}`)

  useEffect(() => {
    checkTokenUrl()

    return () => {
      document.title = t('sarosTitle')
    }
  }, [])

  useEffectCustom(() => {
    if (selectedRecentSwap && selectedRecentSwap.token0Mint) {
      checkTokenUrl(
        selectedRecentSwap.token0Mint,
        selectedRecentSwap.token1Mint
      )
    }
    clearAmount()
  }, [selectedRecentSwap])

  useEffect(() => {
    handleUpdateBalanceTokenSelected({
      listAccount: listAccountOwner
    })
  }, [listAccountOwner, mintToken, accountSol, balanceSol])

  useEffect(() => {
    updateDocumentTitle()
    getPoolLiquidity()
  }, [mintToken?.from?.mintAddress, mintToken?.to?.mintAddress])

  useEffect(() => {
    if (fromCoinInput) {
      handleChangeInput({ value: fromCoinInput, isLoading: false })
    }
  }, [slippage])

  useEffect(() => {
    clearAmount()
  }, [accountSol])

  useEffect(() => {
    getListToken()
  }, [])

  const getListToken = async () => {
    const response = await getListTokenSwap()
    setTokenList(response)
  }

  const handleToggleShowChart = () => {
    setIsShowSwapChart(!isShowSwapChart)
  }

  const onCloseModal = () => {
    window.closeModal()
  }

  const clearAmount = () => {
    setFromCoinInput('')
    setPriceImpact(0)
    setToCoinInput('')
    setUiFee('')
    setSwapRouterData(null)
    setIsLoadingSwapRouter(null)
    setSwapRouterData(null)
  }

  const updateDocumentTitle = () => {
    const newPairCoin = listStableCoin.includes(
      get(mintToken, 'from.mintAddress')
    )
      ? mintToken.to
      : mintToken.from

    const price = window.walletServices.findCoinGeckoPrice(
      get(newPairCoin, 'id', '')
    )

    const formatPrice = formatNumberBro({ number: price, mantissa: 4 })

    document.title =
      formatPrice +
      ' | ' +
      upperCase(get(newPairCoin, 'symbol')) +
      ' | ' +
      t('sarosTitle')
  }

  const getSwapCUSDInfo = async () => {
    setSwapRate({
      base: '1',
      pair: '1'
    })
    setPriceImpact({
      base: '0',
      pair: '0'
    })
    setSwapRouterData(null)
    setIsNotFoundPool(false)
  }

  const checkTokenUrl = (recentToken0Mint, recentToken1Mint) => {
    const search = new URL(window.location.href).search
    const query = new URLSearchParams(search)
    const base = query.get('base')
    const pair = query.get('pair')

    if (
      isBurnOrMintCUSD(base, pair) ||
      isBurnOrMintCUSD(recentToken0Mint, recentToken1Mint)
    ) {
      getSwapCUSDInfo()
    }

    const listToken = window.walletServices.tokenSolana

    const usdcInfo = listToken.find((token) => token.mintAddress === usdcMint)
    const c98Info = listToken.find((token) => token.mintAddress === c98Mint)

    const fromCoin = listToken.find((token) => token.mintAddress === base)
    const toCoin = listToken.find((token) => token.mintAddress === pair)
    // shortcut popup quick swap
    const shortcut1Token = listToken.find(
      (token) => token.mintAddress === shortcut1Mint
    )
    const shortcut0Token = listToken.find(
      (token) => token.mintAddress === shortcut0Mint
    )

    // recennt Swap selected

    const recentSwapToken0 = listToken.find(
      (token) => token.mintAddress === recentToken0Mint
    )

    const recentSwapToken1 = listToken.find(
      (token) => token.mintAddress === recentToken1Mint
    )

    let newFromCoin = fromCoin
    let newToCoin = toCoin

    if (!toCoin || !fromCoin) {
      newFromCoin = fromCoin || usdcInfo
      newToCoin = toCoin || c98Info
    }

    if (shortcut1Token) {
      newToCoin = shortcut1Token
    }
    if (shortcut0Token) {
      newFromCoin = shortcut0Token
    }

    if (recentSwapToken0) {
      newFromCoin = recentSwapToken0
    }
    if (recentSwapToken1) {
      newToCoin = recentSwapToken1
    }

    if ((base && pair) || base === pair || shortcut1Token || shortcut0Token) {
      newToCoin =
        get(newToCoin, 'mintAddress') === usdcInfo.mintAddress &&
        get(newFromCoin, 'mintAddress') === usdcInfo.mintAddress
          ? c98Info
          : get(newToCoin, 'mintAddress') === c98Info.mintAddress &&
            get(newFromCoin, 'mintAddress') === c98Info.mintAddress
            ? usdcInfo
            : newToCoin
    }

    setMintToken({
      from: {
        ...newFromCoin
      },
      to: {
        ...newToCoin
      }
    })
    // check if shortcut not push url on browser
    if (!isShortcut) {
      handlePushStateUrl({
        toMint: newToCoin.mintAddress,
        fromMint: newFromCoin.mintAddress
      })
    }

    handleUpdateBalanceTokenSelected({
      fromCoin: newFromCoin,
      toCoin: newToCoin
    })
  }

  const handleUpdateBalanceTokenSelected = async ({
    listAccount = listAccountOwner
  }) => {
    if (!accountSol) {
      setBalanceToken({
        from: 0,
        to: 0
      })
      return
    }
    const fromCoin = get(mintToken, 'from')
    const toCoin = get(mintToken, 'to')
    const fromMint = get(fromCoin, 'mintAddress')
    const toMint = get(toCoin, 'mintAddress')
    if (!fromCoin || !toCoin) return
    const fromInfoOwner = listAccount.find(
      (item) => item.mint === fromCoin.mintAddress
    )
    const toInfoOwner = listAccount.find(
      (item) => item.mint === toCoin.mintAddress
    )

    const isFromSol = fromMint === NATIVE_SOL.mintAddress
    const isToSol = toMint === NATIVE_SOL.mintAddress

    setBalanceToken({
      from: isFromSol
        ? balanceSol
        : convertWeiToBalance(
          get(fromInfoOwner, 'amount'),
          get(fromCoin, 'decimals')
        ),
      to: isToSol
        ? balanceSol
        : convertWeiToBalance(
          get(toInfoOwner, 'amount'),
          get(toCoin, 'decimals')
        )
    })
  }

  const getPoolLiquidity = async () => {
    if (
      isBurnOrMintCUSD(mintToken?.from?.mintAddress, mintToken?.to?.mintAddress)
    ) {
      if (!cusdFee) {
        const response = await getFeeMintBurnServices()
        setCusdFee({
          ...response
        })
      }

      return getSwapCUSDInfo()
    }
    const res = await findPoolLiquidity({
      fromCoin: fromToken,
      toCoin: toToken
    })
    if (res) {
      setSwapRate(get(res, 'swapRate'))
      setPriceImpact(get(res, 'priceImpact'))
      defaultSwapRouter.current = res
      setSwapRouterData(null)
    } else {
      setIsNotFoundPool(true)
    }
  }

  const onChangeMintToken = (type) => (value) => {
    clearAmount()

    const { coinSelected, isBase } = value
    if (
      get(coinSelected, 'mintAddress') === get(fromToken, 'mintAddress') ||
      get(coinSelected, 'mintAddress') === get(toToken, 'mintAddress')
    ) {
      return handleToggleSwapToken()
    }
    setMintToken({
      from: fromToken,
      to: toToken,
      [type]: coinSelected
    })
    setIsBase(true)
    if (!isShortcut) {
      handlePushStateUrl({
        fromMint:
          type === 'from' ? coinSelected.mintAddress : fromToken.mintAddress,
        toMint: type === 'to' ? coinSelected.mintAddress : toToken.mintAddress
      })
    }
  }

  const onOpenSlippageModal = () => {
    window.openModal({
      isCustomModal: true,
      content: (
        <SlippageModal
          slippageValue={slippage}
          setSlippageValue={setSlippage}
        />
      ),
      onCancel: () => {}
    })
  }

  const onTradeFail = (textMess) => {
    handleTxsFailed({
      callBack: () => {
        window.closeModal()
        window.closeModalFirst()
        setIsSwapping(false)
      },
      message: textMess
    })
  }

  const checkMintOrBurnCUSDSuccess = () => {
    const isMint = fromToken.mintAddress === usdcMint
    const listTokenSol = window.walletServices.tokenSolana
    const cusdToken = listTokenSol.find(
      (item) => item.mintAddress === cusdMint
    )
    const usdcToken = listTokenSol.find(
      (item) => item.mintAddress === usdcMint
    )

    return [
      {
        base: isMint ? usdcToken : cusdToken,
        pair: isMint ? cusdToken : usdcToken,
        poolAddress: '',
        poolType: sarosSwapPoolType.C98
      }
    ]
  }

  const checkParamsSwapSuccess = async () => {
    const swapData = await Promise.all(
      smartRouter.map(async (item) => {
        const token0Mint = get(item, 'token0')
        const token1Mint = get(item, 'token1')

        const infoToken0 = await TokenProgramService.getInfoTokenInfoByMint(
          token0Mint
        )
        const infoToken1 = await TokenProgramService.getInfoTokenInfoByMint(
          token1Mint
        )
        const accountToken0 =
          await TokenProgramService.findAssociatedTokenAddress(
            new PublicKey(accountSol),
            new PublicKey(token0Mint)
          )
        const accountToken1 =
          await TokenProgramService.findAssociatedTokenAddress(
            new PublicKey(accountSol),
            new PublicKey(token1Mint)
          )

        return {
          base: {
            id: get(infoToken0, 'id', ''),
            mintAddress: get(infoToken0, 'mintAddress', ''),
            decimals: get(infoToken0, 'decimals', ''),
            address: accountToken0.toString(),
            symbol: get(infoToken0, 'symbol', '')
          },
          pair: {
            id: get(infoToken1, 'id', ''),
            mintAddress: get(infoToken1, 'mintAddress', ''),
            decimals: get(infoToken1, 'decimals', ''),
            address: accountToken1.toString(),
            symbol: get(infoToken1, 'symbol', '')
          },
          poolAddress: get(item, 'address'),
          poolType: sarosSwapPoolType.C98
        }
      })
    )
    return swapData
  }

  const onLoadDefiPrice = () => {
    try {
      const base = window.walletServices.findCoinGeckoPrice(fromToken.id)
      const pair = window.walletServices.findCoinGeckoPrice(toToken.id)
      return { base, pair }
    } catch (error) {
      return { base: 0, pair: 0 }
    }
  }

  const handleSwapSuccess = async ({ hash, isCreateAccount }) => {
    const { base, pair } = onLoadDefiPrice()

    const isMintOrBurnCUSD = isBurnOrMintCUSD(
      fromToken.mintAddress,
      toToken.mintAddress
    )

    if (!isCreateAccount) {
      // const fromTokenAccount =
      //   await TokenProgramService.findAssociatedTokenAddress(
      //     new PublicKey(accountSol),
      //     new PublicKey(fromToken.mintAddress)
      //   )
      // const toTokenAccount =
      //   await TokenProgramService.findAssociatedTokenAddress(
      //     new PublicKey(accountSol),
      //     new PublicKey(fromToken.mintAddress)
      //   )
      // await BaseAPI.postData('trade/v2', {
      //   source: 'saros',
      //   hash,
      //   base: {
      //     price: base,
      //     name: fromToken.name,
      //     symbol: fromToken.symbol,
      //     address: fromTokenAccount,
      //     mintAddress: fromToken.mintAddress
      //   },
      //   pair: {
      //     price: pair,
      //     name: toToken.name,
      //     symbol: toToken.symbol,
      //     address: toTokenAccount,
      //     mintAddress: toToken.mintAddress
      //   },
      //   chain: chainType.solana,
      //   rate: get(swapRate, 'base', ''),
      //   size: fromCoinInput,
      //   sizeTo: toCoinInput,
      //   price: get(swapRate, 'pair', ''),
      //   side: isBase ? 'buy' : 'sell', // isBase === true -> buy
      //   owner: accountSol,
      //   tradeType: 'saros'
      // })
      await BaseAdapter.postData('saros/swap', {
        hash,
        owner: accountSol,
        swapData: isMintOrBurnCUSD
          ? checkMintOrBurnCUSDSuccess()
          : await checkParamsSwapSuccess()
      })
    }

    if (isMintOrBurnCUSD) {
      const isMint = fromToken.mintAddress === usdcMint
      const selectedPackage = get(
        cusdFee,
        `${isMint ? 'packageMint' : 'packageBurn'}`
      )
      await BaseAdapter.postData('eco/dollar/history', {
        isMint,
        id: get(selectedPackage, 'id'),
        price: 1,
        rate: `${formatNumberBro({ number: fromCoinInput })} ${upperCase(
          get(fromToken, 'symbol')
        )} to ${formatNumberBro({ number: toCoinInput })} ${upperCase(
          get(toToken, 'symbol')
        )}`,
        amount: fromCoinInput,
        hash,
        from: accountSol,
        to: accountSol,
        fee: get(selectedPackage, 'fee')
      })
    }
    const listAccount = await handleGetAllAccountOwner()
    handleUpdateBalanceTokenSelected({
      listAccount
    })
    setIsSwapping(false)
    viewCompleteHash({ hash })

    // set recent swap redux here
    updateRecentSwapList(
      get(fromToken, 'mintAddress'),
      get(toToken, 'mintAddress')
    )

    // close popup shortcut
    window.closeModalFirst()
  }

  const onFetchSPLAccount = async (routers) => {
    try {
      const fromMint = get(fromToken, 'mintAddress')
      const toMint = get(toToken, 'mintAddress')
      const middleMint = get(routers[0], 'token1')
      const listMint = [fromMint, toMint, middleMint]
      const [fromAccount, toAccount, middleAccount] = listMint.map((mint) => {
        const info = listAccountOwner.find((item) => item.mint === mint)
        return get(info, 'address', '')
      })
      return { fromAccount, toAccount, middleAccount }
    } catch (err) {
      return {
        fromAccount: '',
        toAccount: '',
        middleAccount: ''
      }
    }
  }

  const handleCreateAccount = () => {
    window.openModalFirst({
      title: t('createAccount'),
      content: <OrderSetup />,
      type: 'confirm',
      onCancel: () => {},
      onOk: async () => {
        setIsSwapping(true)
        const fromMint = get(fromToken, 'mintAddress')
        const toMint = get(toToken, 'mintAddress')
        const middleMint = get(smartRouter[0], 'token1')
        const connection = genConnectionSolana()
        const owner = await genOwnerSolana(accountSol)
        const hash = await SarosSwapService.createAccountPreSwapRoute(
          connection,
          owner,
          new PublicKey(fromMint),
          new PublicKey(toMint),
          new PublicKey(middleMint),
          () => {
            window.closeModalFirst()
            handleTxsSuccess({
              message: t('pleaseWaitOrder')
            })
          },
          async (hash) => {
            handleSwapSuccess({
              hash,
              isCreateAccount: true
            })
          }
        )
        const { isErr, data } = hash
        if (isErr) {
          setIsSwapping(false)
          return onTradeFail(data)
        }
      }
    })
  }

  const handleMintOrBurnCUSD = async () => {
    setIsSwapping(true)
    let response
    const isMint = fromToken.mintAddress === usdcMint

    const { walletActive, deviceId } = store.getState()

    const connection = genConnectionSolana()
    const owner = await genOwnerSolana(walletActive, deviceId)
    const amount = convertBalanceToWei(fromCoinInput, 6)

    const convertAmount = new BN(amount.toString())

    if (isMint) {
      response = await CusdFactoryService.mint(
        accountSol,
        connection,
        owner,
        MINTER_ADDRESS,
        CUSD_MINT,
        convertAmount,
        CHAIN_LINK_PROGRAM,
        CUSD_PROGRAM_ID
      )
    } else {
      response = await CusdFactoryService.burn(
        accountSol,
        connection,
        owner,
        BURNER_ADDRESS,
        CUSD_MINT,
        new BN(amount.toString()),
        CHAIN_LINK_PROGRAM,
        CUSD_PROGRAM_ID
      )
    }
    setIsSwapping(false)
    if (response && !response.isErr) {
      handleTxsSuccess({
        callBack: () => {
          onCloseModal()
          clearAmount()
        },
        message: t('pleaseWaitOrder')
      })
      handleSwapSuccess({ hash: response })
    } else {
      setIsSwapping(false)
      return onTradeFail(response && response.data)
    }
  }

  const handlePlaceOrderSmartRouter = async () => {
    if (isBurnOrMintCUSD(fromToken.mintAddress, toToken.mintAddress)) {
      return handleMintOrBurnCUSD()
    }

    if (!smartRouter) return

    setIsSwapping(true)
    const connection = genConnectionSolana()
    const payerAccount = await genOwnerSolana(accountSol)
    const signers = [payerAccount]

    const routers = await Promise.all(
      smartRouter.map(async (route) => {
        const type = get(route, 'type.type')
        const routeAddress = get(route, 'address', '')
        const poolAuthority = get(route, 'authority', '')
        let poolSource = get(route, 'token0', '')
        let poolDestination = get(route, 'token1', '')
        const amountIn = new BN(get(route, 'amountIn'))
        const amountOut = new BN(get(route, 'amountOut'))

        if (type === 'orca_whirpools') {
          poolSource = get(route, 'token0Account', '')
          poolDestination = get(route, 'token1Account', '')
        }

        return {
          poolAddress: new PublicKey(routeAddress.toString()),
          poolAuthority: new PublicKey(poolAuthority.toString()),
          poolSource: new PublicKey(poolSource.toString()),
          poolDestination: new PublicKey(poolDestination.toString()),
          amountIn,
          amountOut,
          sourceToken: new PublicKey(get(route, 'token0')),
          destinationToken: new PublicKey(get(route, 'token1')),
          type
        }
      })
    )
    const dataFeeTxs = {
      ...toToken,
      mintAddress: new PublicKey(get(toToken, 'mintAddress')),
      amount: new BN(
        parseFloat(uiFee) <= 0
          ? 0
          : convertBalanceToWei(uiFee, get(toToken, 'decimals', 6))
      )
    }

    const transactionList = await AggregatorService.buildSwapTransaction(
      connection,
      routers,
      payerAccount.publicKey,
      listPoolNew,
      signers,
      dataFeeTxs
    )
    const hash = await postBaseSendTxsNew(
      connection,
      transactionList,
      signers,
      true,
      () => {
        handleTxsSuccess({
          callBack: () => {
            onCloseModal()
            clearAmount()
          },
          message: t('pleaseWaitOrder')
        })
      },

      (hash) => {
        handleSwapSuccess({
          hash
        })
      }
    )

    const { isErr, data } = hash
    if (isErr) {
      setIsSwapping(false)
      return onTradeFail(data)
    }
  }

  const onOpenReviewSwapModal = async () => {
    const isBurnOrMint = isBurnOrMintCUSD(
      fromToken.mintAddress,
      toToken.mintAddress
    )

    if (getLength(smartRouter) > 0) {
      const { fromAccount, middleAccount, toAccount } = await onFetchSPLAccount(
        smartRouter
      )
      if (!isBurnOrMint && !(fromAccount && middleAccount && toAccount)) {
        return handleCreateAccount()
      }
    }

    window.openModal({
      isCustomModal: true,
      content: (
        <ReviewSwapModal
          fromCoinInput={fromCoinInput}
          toCoinInput={toCoinInput}
          renderPriceImpact={renderPriceImpact}
          renderRate={renderRate}
          fromToken={fromToken}
          toToken={toToken}
          mintToken={mintToken}
          isBase={isBase}
          fee={feeFull}
          uiFee={uiFee}
          isSwapping={isSwapping}
          onCancel={onCloseModal}
          onConfirm={handlePlaceOrderSmartRouter}
        />
      )
    })
  }

  const getBurnFee = async (amount) => {
    const isMint = fromToken.mintAddress === usdcMint
    const feePercent = parseFloat(get(cusdFee, 'packageBurn.fee', 0))
    const feeBurn = (feePercent * amount) / 100
    if (!isMint) {
      setUiFee(formatNumberBro({ number: feeBurn, mantissa: 4 }))
    }
  }

  const handleChangeInput = ({ value, isLoading }) => {
    setFromCoinInput(value)
    setIsFocusBaseInput(false)
    if (isBurnOrMintCUSD(fromToken.mintAddress, toToken.mintAddress)) {
      setToCoinInput(value)
      getBurnFee(value)

      return getSwapCUSDInfo()
    }
    return debounceChangeDevelop({ amount: value, isLoading })
  }

  const debounceChange = useCallback(
    debounce(async ({ amount, isLoading }) => {
      if (Number(amount) > 0) {
        setIsLoadingSwapRouter(isLoading)
        const response = await BaseAPI.postData('saros/swap/getPairs', {
          token0: fromToken,
          token1: toToken,
          amount,
          slippage: slippage * 100
        })
        if (!response || isEmpty(response)) {
          setIsLoadingSwapRouter(false)
          return setIsNotFoundPool(true)
        }
        const amountOut = get(response, 'amount', '')
        const router = get(response, 'router')
        const impact = parseFloat(get(response, 'impact', 0))
        // const uiFee = calculateUiFee(amountOut)
        // const newAmountOut = parseFloat(amountOut) - parseFloat(uiFee)

        // setUiFee(formatNumberBro({ number: uiFee, mantissa: 4 }))

        setToCoinInput(amountOut)
        setPriceImpact({ ...priceImpact, base: impact }) // only get base,  b/c getPriceImpact is trigger when user input amount
        setSmartRouter(router)
        setIsNotFoundPool(false)

        setIsLoadingSwapRouter(false)
      } else {
        setToCoinInput('')
        setSwapRouterData(null)
      }
    }, 250),
    [mintToken, slippage, tokenList, isBase]
  )

  const debounceChangeDevelop = useCallback(
    debounce(async ({ amount, isLoading }) => {
      if (Number(amount) > 0) {
        setIsLoadingSwapRouter(isLoading)
        const response = await BaseAPI.postData('saros/swap/getPairsV2', {
          token0: fromToken,
          token1: toToken,
          amount,
          slippage: slippage * 100
        })
        if (!response || isEmpty(response)) {
          setIsLoadingSwapRouter(false)
          return setIsNotFoundPool(true)
        }

        const amountOut = get(response, 'router.routerData.amount', '')
        const router = get(response, 'router.routerData.router')
        const impact = parseFloat(get(response, 'router.routerData.impact', 0))
        const uiFee = calculateUiFee(amountOut)
        const newAmountOut = parseFloat(amountOut) - parseFloat(uiFee)

        setUiFee(formatNumberBro({ number: uiFee, mantissa: 4 }))

        setToCoinInput(newAmountOut)
        setPriceImpact({ ...priceImpact, base: impact })
        setSmartRouter(router)
        setIsNotFoundPool(false)

        const newSwapRouterData = cloneDeep(defaultSwapRouter.current)
        newSwapRouterData.ammCompareData[isBase ? 'base' : 'pair'] = get(
          response,
          'AmmCompareData',
          ''
        )
        newSwapRouterData.swapRate[isBase ? 'base' : 'pair'] = get(
          response,
          'router.routerData.amount',
          ''
        )
        newSwapRouterData.priceImpact[isBase ? 'base' : 'pair'] = get(
          response,
          'router.routerData.impact',
          ''
        )
        newSwapRouterData.routerData[isBase ? 'base' : 'pair'] = get(
          response,
          'router.routerData',
          ''
        )
        newSwapRouterData.youSave[isBase ? 'base' : 'pair'] = get(
          response,
          'youSave',
          ''
        )

        setSwapRouterData(newSwapRouterData)
        setIsLoadingSwapRouter(false)
      } else {
        setToCoinInput('')
        setSwapRouterData(null)
      }
    }, 250),
    [mintToken, slippage, tokenList, isBase]
  )

  const onRefreshBalance = async () => {
    const listAccount = await handleGetAllAccountOwner()
    handleUpdateBalanceTokenSelected({
      listAccount
    })
  }

  const disableButton = useMemo(() => {
    const newPriceImpact = get(priceImpact, isBase ? 'base' : 'pair')
    const newFromCoinInput = parseFloat(fromCoinInput || 0)
    let objStatus = {
      isDisable: false,
      message: newFromCoinInput > 0 ? t('swap') : t('inputAmount')
    }

    const isSol = get(fromToken, 'mintAddress') === NATIVE_SOL.mintAddress
    const isFeeError = isSol ? Number(fromBalance) - numFee <= 0 : false

    if (newFromCoinInput && isNotFoundPool) {
      objStatus = {
        isDisable: true,
        message: 'poolNotFound'
      }
      return objStatus
    }

    if (!Number(newFromCoinInput)) {
      objStatus.isDisable = true
      return objStatus
    }

    if (!newFromCoinInput || !toCoinInput) {
      objStatus.isDisable = true
      return objStatus
    }

    if (isFeeError) {
      objStatus = {
        isDisable: true,
        message: 'feeErr'
      }
      return objStatus
    }

    if (parseFloat(fromBalance) < newFromCoinInput) {
      objStatus = {
        isDisable: true,
        message: 'amountErr'
      }
      return objStatus
    }

    if (parseFloat(newPriceImpact) > 50) {
      objStatus = {
        isDisable: true,
        message: 'priceImpactMustBeLessThan'
      }
      return objStatus
    }

    return objStatus
  }, [
    fromCoinInput,
    toCoinInput,
    fromToken,
    toToken,
    isNotFoundPool,
    priceImpact,
    isBase
  ])

  const handleToggleSwapToken = () => {
    // setMintToken({
    //   // switch from -> to , to -> from,
    //   from: toToken,
    //   to: fromToken
    // })

    // if is shortcut not push url
    if (!isShortcut) {
      handlePushStateUrl({
        fromMint: toToken.mintAddress,
        toMint: fromToken.mintAddress
      })
    }

    setIsBase(!isBase)
    clearAmount()
    onAnimateSwap()
  }

  const onAnimateSwap = () => {
    // base fly down, pair fly up
    const elBase = document.getElementById('input-base')
    const elPair = document.getElementById('input-pair')
    if (elBase) {
      elBase.classList.add('move-down-animation')
      setTimeout(() => {
        elBase.classList.remove('move-down-animation')
        elBase.classList.add('reset-animation-swap')
      }, 200)
    }
    if (elPair) {
      elPair.classList.add('move-up-animation')
      setTimeout(() => {
        elPair.classList.remove('move-up-animation')
        elPair.classList.add('reset-animation-swap')
      }, 200)
    }
  }

  const onShowShareModal = () => {
    window.openModal({
      isCustomModal: true,
      className: styles.listTokenModal,
      isFullHeight: true,
      content: <ShareModal type="swap" token0={fromToken} token1={toToken} />,
      onCancel: () => {}
    })
  }

  const onFocusBaseInput = (e) => {
    setIsFocusBaseInput(true)
  }

  const renderRate = useMemo(() => {
    const formatRate = formatNumberBro({
      number: calculateRate,
      mantissa: 6
    })
    return `1 ${get(fromToken, 'symbol')} ~ ${formatRate}  ${get(
      toToken,
      'symbol'
    )}`
  }, [calculateRate, fromToken, toToken, isBase])

  const renderPriceImpact = useMemo(() => {
    const newPriceImpact = get(priceImpact, 'base')
    if (!fromCoinInput) {
      return '-'
    }
    return parseFloat(newPriceImpact) < 0.0001
      ? '< 0.0001 %'
      : `${formatNumberBro({ number: newPriceImpact, mantissa: 3 })}%`
  }, [priceImpact, isBase, fromCoinInput])

  const renderClassNameGrid = useMemo(() => {
    if (isShortcut) {
      return 'center-container'
    }
    return 'grid-container'
    // return isShowSwapChart ? 'grid-container' : 'center-container'
  }, [isProduction, isShowSwapChart, isShortcut])

  const renderComponentSwap = () => {
    return (
      <div className={cn('box-wrapper right-section')}>
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="capitalize text-l semibold color-theme">
              {t('swap')}
            </div>
            <div className="d-flex align-items-center">
              {!isShortcut && (
                <Tooltip
                  content={t(!isShowSwapChart ? 'openChart' : 'hideChart')}
                >
                  <Icon
                    className={cn(
                      selectedLeftComponent === 'chart' && 'color-theme'
                    )}
                    name={!isShowSwapChart ? 'diagram' : 'diagram_close'}
                    onClick={handleToggleShowChart}
                  />
                </Tooltip>
              )}
              <Tooltip content={t('shareSwap')}>
                <Icon
                  name="app_share"
                  onClick={onShowShareModal}
                  backgroundClassName="icon-share-container"
                />
              </Tooltip>

              <SettingsController
                onClickSetting={onOpenSlippageModal}
                onCompleteCountdown={onRefreshBalance}
              />
            </div>
          </div>
          <div ref={inputBaseRef}>
            <InputTokenAmount
              id="input-base"
              onFocusInput={onFocusBaseInput}
              balance={JSON.stringify(Number(fromBalance))}
              onchangeInput={handleChangeInput}
              valueInput={fromCoinInput}
              coinSelected={fromToken}
              onChangeToken={onChangeMintToken('from')}
              tokenList={tokenList}
              isBase={isBase}
              className="mt-5"
              coinFilter={toToken}
              isShortcut={isShortcut}
              isShowHalf
            />
          </div>

          <div className="d-flex justify-content-center mt-3 mb-3">
            {/* <Icon
              onClick={handleToggleSwapToken}
              name="web_swap"
              className="text-l"
            /> */}
            <SwapRecent isShowList={isFocusBaseInput}>
              <Icon
                onClick={handleToggleSwapToken}
                name="web_swap"
                className="text-l"
              />
            </SwapRecent>
          </div>

          <InputTokenAmount
            id="input-pair"
            type="text"
            // balance={toBalance}
            balance={JSON.stringify(Number(toBalance))}
            valueInput={roundingNumber(toCoinInput, 6)}
            isDisabledInput={true}
            coinSelected={toToken}
            onChangeToken={onChangeMintToken('to')}
            tokenList={tokenList}
            isBase={isBase}
            className="mb-4"
            coinFilter={fromToken}
            isShortcut={isShortcut}
            isDisableMaxAmount={true}
          />

          <div className={cn(isShowSwapChart ? '' : 'info-text-container')}>
            <div className="d-flex justify-content-between mt-2 text-sm">
              <div className="color-grey">{t('rate')}</div>
              <div className="uppercase">{renderRate}</div>
            </div>
            {uiFee && (
              <div className="d-flex justify-content-between mt-2 text-sm">
                <div className="color-grey">{t('uiFee')}</div>
                <div className="uppercase">
                  {parseFloat(uiFee) <= 0.001 ? '< 0.001' : '0'}{' '}
                  {get(toToken, 'symbol', '')}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between mt-2 text-sm">
              <div className="color-grey">{t('priceImpact')}</div>
              <div
                style={{
                  color: `${
                    parseFloat(get(priceImpact, 'base')) > 50 ? '#b94f3d' : ''
                  }`
                }}
              >
                {renderPriceImpact}
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2 text-sm">
              <div className="color-grey">{t('swapFee')}</div>
              <div>{feeFull}</div>
            </div>
          </div>
        </div>
        <Button
          isLoading={getLength(listPoolNew) <= 0}
          isDisable={disableButton.isDisable}
          onClick={onOpenReviewSwapModal}
          className="button-swap"
          isFullWidth
        >
          {t(disableButton.message)}
        </Button>
      </div>
    )
  }

  return (
    <div className={cn(styles.newSwapContainer, 'container')}>
      <div className={cn(renderClassNameGrid)}>
        {!isShortcut && (
          <>
            <div className="left-section ">
              <CompareSwap isBase={isBase} isVisble={!isShowSwapChart} />
              <SwapChart
                fromCoin={fromToken}
                toCoin={toToken}
                isVisble={isShowSwapChart}
              />
            </div>
          </>
        )}

        {renderComponentSwap()}
      </div>
    </div>
  )
}

export default NewSwapScreen
