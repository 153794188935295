import images from 'assets/images'
import Button from 'components/common/Button'
import { Icon } from 'components/Icon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.scss'

const ConnectWallet = () => {
  const { t } = useTranslation()

  const handleCheckEx = () => {
    window.openModal({
      title: t('coin98NotInstall'),
      content: t('installCoin98'),
      type: 'warning',
      onCancel: () => {},
      onOk: async () => {
        window.open(
          'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg'
        )
        window.closeModal()
      }
    })
  }

  return (
    <div className="connect-wallet">
      <div className="connect-wallet__content">
        <Icon type="lg" name="saros_connect_fail" className="icon-wallet" />
        <p className="description">{t('connectToSee')}</p>
      </div>
      <Button
        onClick={handleCheckEx}
        isFullWidth
        className="connect-wallet__actions"
      >
        {t('connectWallet')}
      </Button>
    </div>
  )
}

export default ConnectWallet
