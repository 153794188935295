import { BorshCoder, Idl } from '@project-serum/anchor'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import {
  AccountMeta,
  PublicKey,
  SystemProgram,
  TransactionInstruction
} from '@solana/web3.js'
import BN from 'bn.js'
import { SarosFarmIdl } from 'common/farm/SarosFarmIdl'

const coder = new BorshCoder(SarosFarmIdl)

export class SarosFarmInstructionService {
  static createPoolInstruction (
    rootAddress,
    poolAddress,
    poolPath,
    poolNonce,
    poolAuthorityNonce,
    stakingTokenMint,
    sarosFarmProgramAddress
  ) {
    const request = {
      poolPath,
      poolNonce,
      poolAuthorityNonce,
      stakingTokenMint
    }
    const data = coder.instruction.encode('createPool', request)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: true },
      { pubkey: poolAddress, isSigner: false, isWritable: true },
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static createPoolRewardInstruction (
    rootAddress,
    poolRewardAddress,
    poolRewardNonce,
    poolRewardAuthorityNonce,
    poolAddress,
    rewardTokenMint,
    rewardPerBlock,
    rewardStartBlock,
    rewardEndBlock,
    sarosFarmProgramAddress
  ) {
    const request = {
      poolRewardNonce,
      poolRewardAuthorityNonce,
      rewardTokenMint,
      rewardPerBlock,
      rewardStartBlock,
      rewardEndBlock
    }
    const data = coder.instruction.encode('createPoolReward', request)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: true },
      { pubkey: poolAddress, isSigner: false, isWritable: false },
      { pubkey: poolRewardAddress, isSigner: false, isWritable: true },
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static createUserPoolInstruction (
    payerAddress,
    poolAddress,
    userPoolAddress,
    userPoolNonce,
    sarosFarmProgramAddress
  ) {
    const request = {
      userPoolNonce
    }
    const data = coder.instruction.encode('createUserPool', request)

    const keys = [
      { pubkey: payerAddress, isSigner: true, isWritable: true },
      { pubkey: poolAddress, isSigner: false, isWritable: false },
      { pubkey: userPoolAddress, isSigner: false, isWritable: true },
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static stakePoolInstruction (
    poolAddress,
    poolStakingTokenAddress,
    userAddress,
    userPoolAddress,
    userStakingTokenAddress,
    amount,
    sarosFarmProgramAddress
  ) {
    const request = {
      amount
    }
    const data = coder.instruction.encode('stakePool', request)

    const keys = [
      { pubkey: poolAddress, isSigner: false, isWritable: true },
      { pubkey: poolStakingTokenAddress, isSigner: false, isWritable: true },
      { pubkey: userAddress, isSigner: true, isWritable: false },
      { pubkey: userPoolAddress, isSigner: false, isWritable: true },
      { pubkey: userStakingTokenAddress, isSigner: false, isWritable: true },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static decodePoolAccount (data) {
    const result = coder.accounts.decode('Pool', data)
    return result
  }

  static createUserPoolRewardInstruction (
    payerAddress,
    poolRewardAddress,
    userPoolRewardAddress,
    userPoolRewardNonce,
    sarosFarmProgramAddress
  ) {
    const request = {
      userPoolRewardNonce
    }
    const data = coder.instruction.encode('createUserPoolReward', request)

    const keys = [
      { pubkey: payerAddress, isSigner: true, isWritable: true },
      { pubkey: poolRewardAddress, isSigner: false, isWritable: false },
      { pubkey: userPoolRewardAddress, isSigner: false, isWritable: true },
      { pubkey: SystemProgram.programId, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static stakePoolRewardInstruction (
    poolAddress,
    poolRewardAddress,
    userAddress,
    userPoolAddress,
    userPoolRewardAddress,
    sarosFarmProgramAddress
  ) {
    const request = {}
    const data = coder.instruction.encode('stakePoolReward', request)

    const keys = [
      { pubkey: poolAddress, isSigner: false, isWritable: false },
      { pubkey: poolRewardAddress, isSigner: false, isWritable: true },
      { pubkey: userAddress, isSigner: true, isWritable: false },
      { pubkey: userPoolAddress, isSigner: false, isWritable: true },
      { pubkey: userPoolRewardAddress, isSigner: false, isWritable: true }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static claimRewardInstruction (
    poolRewardAddress,
    poolRewardAuthorityAddress,
    poolRewardTokenAddress,
    userAddress,
    userPoolRewardAddress,
    userRewardTokenAddress,
    sarosFarmProgramAddress
  ) {
    const request = {}
    const data = coder.instruction.encode('claimReward', request)

    const keys = [
      { pubkey: poolRewardAddress, isSigner: false, isWritable: true },
      {
        pubkey: poolRewardAuthorityAddress,
        isSigner: false,
        isWritable: false
      },
      { pubkey: poolRewardTokenAddress, isSigner: false, isWritable: true },
      { pubkey: userAddress, isSigner: true, isWritable: false },
      { pubkey: userPoolRewardAddress, isSigner: false, isWritable: true },
      { pubkey: userRewardTokenAddress, isSigner: false, isWritable: true },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static unstakePoolInstruction (
    poolAddress,
    poolAuthorityAddress,
    poolStakingTokenAddress,
    userAddress,
    userPoolAddress,
    userStakingTokenAddress,
    amount,
    sarosFarmProgramAddress
  ) {
    const request = {
      amount
    }
    const data = coder.instruction.encode('unstakePool', request)

    const keys = [
      { pubkey: poolAddress, isSigner: false, isWritable: true },
      { pubkey: poolAuthorityAddress, isSigner: false, isWritable: false },
      { pubkey: poolStakingTokenAddress, isSigner: false, isWritable: true },
      { pubkey: userAddress, isSigner: true, isWritable: false },
      { pubkey: userPoolAddress, isSigner: false, isWritable: true },
      { pubkey: userStakingTokenAddress, isSigner: false, isWritable: true },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static unstakePoolRewardInstruction (
    poolAddress,
    poolRewardAddress,
    userAddress,
    userPoolAddress,
    userPoolRewardAddress,
    sarosFarmProgramAddress
  ) {
    const request = {
    }
    const data = coder.instruction.encode('unstakePoolReward', request)

    const keys = [
      { pubkey: poolAddress, isSigner: false, isWritable: false },
      { pubkey: poolRewardAddress, isSigner: false, isWritable: true },
      { pubkey: userAddress, isSigner: true, isWritable: false },
      { pubkey: userPoolAddress, isSigner: false, isWritable: true },
      { pubkey: userPoolRewardAddress, isSigner: false, isWritable: true }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static withdrawTokenInstruction (
    rootAddress,
    baseAddress,
    authorityAddress,
    authorityNonce,
    poolTokenAddress,
    destinationTokenAddress,
    amount,
    sarosFarmProgramAddress
  ) {
    const request = {
      amount,
      authorityNonce
    }
    const data = coder.instruction.encode('withdrawToken', request)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: false },
      { pubkey: baseAddress, isSigner: false, isWritable: false },
      { pubkey: authorityAddress, isSigner: false, isWritable: false },
      { pubkey: poolTokenAddress, isSigner: false, isWritable: true },
      { pubkey: destinationTokenAddress, isSigner: false, isWritable: true },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static updatePoolRewardParamsInstruction (
    rootAddress,
    poolAddress,
    rootRewardTokenAddress,
    poolRewardTokenAddress,
    newRewardPerBlock,
    newStartBlock,
    newEndBlock,
    sarosFarmProgramAddress
  ) {
    const request = {
      newRewardPerBlock,
      newStartBlock,
      newEndBlock
    }
    const data = coder.instruction.encode('updatePoolRewardParams', request)

    const keys = [
      { pubkey: rootAddress, isSigner: true, isWritable: false },
      { pubkey: poolAddress, isSigner: false, isWritable: true },
      { pubkey: rootRewardTokenAddress, isSigner: false, isWritable: true },
      { pubkey: poolRewardTokenAddress, isSigner: false, isWritable: true },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false }
    ]

    return new TransactionInstruction({
      data,
      keys,
      programId: sarosFarmProgramAddress
    })
  }

  static decodePoolRewardAccount (
    data
  ) {
    const result = coder.accounts.decode('PoolReward', data)
    return result
  }

  static decodeUserPoolAccount (
    data
  ) {
    const result = coder.accounts.decode('UserPool', data)
    return result
  }

  static decodeUserPoolRewardAccount (
    data
  ) {
    const result = coder.accounts.decode('UserPoolReward', data)
    return result
  }
}
