/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-undef */

import {
  PublicKey,
  Keypair as SolAccount,
  Transaction,
  Connection,
  SystemProgram,
  sendAndConfirmTransaction
} from '@solana/web3.js'

import { ASSOCIATED_TOKEN_PROGRAM_ID, Token } from '@solana/spl-token'
import { store } from 'controller/redux/store/configureStore'
import { IS_DEV, LIST_TOKEN_DEFAULT_TEST, SOLANA_LINK } from 'common/constants'
import {
  convertWeiToBalance,
  getLength,
  sleep,
  getItemStorage,
  lowerCase,
  showNotificationToast
} from './functions'
import * as BufferLayout from 'buffer-layout'
import StoreActions from 'controller/redux/actions/storeActions'
import { NATIVE_SOL_RAYDIUM } from './raydium/constants'
import { get, isObject } from 'lodash'
import { parseTokenAccountData } from './common'
import { SERUM_PROGRAM_ID_V3 } from './raydium/raydium-id'
import { DeviceUUID } from 'device-uuid'
import { derivePath } from 'ed25519-hd-key'

import nacl from 'tweetnacl'
import RPCSolana from 'controller/api/rpcSolana'
import { TokenProgramService } from './pool/tokenProgramService'
import { TokenProgramInstructionService } from './pool/tokenProgramInstructionService'

export const FEE_SOL = 0.000005

const pathSollet = "m/44'/501'/0'/0'"

const bip39 = require('bip39')

const bs58 = require('bs58')

export const SRM_PROGRAM_V3 = new PublicKey(SERUM_PROGRAM_ID_V3)

export const NATIVE_SOL = {
  id: 'solana',
  mintAddress: 'So11111111111111111111111111111111111111112',
  symbol: 'SOL',
  name: 'Solana',
  icon: 'https://cdn.jsdelivr.net/gh/trustwallet/assets/blockchains/solana/info/logo.png',
  decimals: 9
}

export const USDC_MINT = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
export const C98_MINT = 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9'

const txsFail = 'txsFail'
export const messFail = [
  'gasSolNotEnough',
  'tradeErrFund',
  'sizeTooSmall',
  'txsFail',
  'tooLarge',
  'exceedsLimit'
]

export const generateSeed = async (mnemonic) => {
  const seed = await bip39.mnemonicToSeed(mnemonic)
  return seed
}

export const convertBase58 = (secretKey, isDecode) => {
  return isDecode
    ? bs58.decode(secretKey)
    : bs58.encode(Buffer.from(secretKey, 'hex'))
}

export function encodeMessErr (mess) {
  const stringResult = lowerCase(
    mess ? get(mess, 'mess', mess).toString() : ''
  )
  if (stringResult.includes('error')) {
    let mess = txsFail
    switch (true) {
    case stringResult.includes('exceeds desired slippage limit'):
      mess = 'exceedsLimit'
      break
    case stringResult.includes('insufficient funds'):
    case stringResult.includes('insufficient'):
      mess = 'tradeErrFund'
      break
    case stringResult.includes('size too small'):
      mess = 'sizeTooSmall'
      break
    case stringResult.includes('Transaction too large'):
      mess = 'tooLarge'
      break
    case stringResult.includes('attempt to debit an account but'):
    case stringResult.includes('gasSolNotEnough'):
      mess = 'gasSolNotEnough'
      break
      // case stringResult.includes('0x1'):
      //   mess = 'gasSolNotEnough';
      //   break;
    case stringResult.includes('the capitalization checksum'):
      mess = null
      break
    case stringResult.includes('zero trading'):
      mess = 'amountTokenNotEnough'
      break
    }
    return mess
  } else {
    return txsFail
  }
}

export const genConnectionSolana = () => {
  // const rpc = get(window, 'walletServices.setting.fetch')
  const rpc = 'https://information.coin98.com/api/solanaV5'

  const connectionSolana = new Connection(
    IS_DEV ? 'https://api.devnet.solana.com' : rpc,
    {
      commitment: 'confirmed',
      httpHeaders: {
        development: 'coin98'
      }
    }
  )

  return connectionSolana
}

export const getBalanceSol = async (addressWallet) => {
  try {
    const { accountSol } = store.getState()
    const newAddress = addressWallet || accountSol
    const connection = genConnectionSolana()

    const balanceSOL = await connection.getBalance(new PublicKey(newAddress))
    const balanceConvert = convertWeiToBalance(balanceSOL, NATIVE_SOL.decimals)
    store.dispatch(StoreActions.setBalanceSol(balanceConvert))
    return balanceConvert
  } catch (err) {
    console.log({ err })
    store.dispatch(StoreActions.setBalanceSol(0))
    return 0
  }
}

export async function genOwnerSolana (addressAccount) {
  const isMobile = window.innerWidth < 576
  if (isMobile) {
    const publicKey = new PublicKey(addressAccount)
    return { publicKey }
  }

  // web
  let privateKey, seed
  const { walletActive } = store.getState()

  const wallet = { ...walletActive }

  const uuid = new DeviceUUID().get()
  const { deviceId } = store.getState()

  if (getLength(wallet.privateKey) !== 0) {
    const decryptPrivateKey = await window?.coin98?.sol.request({
      method: 'aes_decrypt_coin98',
      params: { data: wallet.privateKey, deviceId, uuid }
    })

    if (!decryptPrivateKey.includes(',')) {
      privateKey = decryptPrivateKey
    }
  }

  if (!privateKey) {
    const decryptMnemonic = await window?.coin98?.sol.request({
      method: 'aes_decrypt_coin98',
      params: { data: wallet.mnemonic, deviceId, uuid }
    })
    seed = await generateSeed(decryptMnemonic)
  }

  const owner = createSolanaWallet(seed, wallet.isSollet, privateKey)

  if (owner.publicKey.toString() === (wallet.walletAddress || wallet.address)) {
    return owner
  } else {
    if (privateKey) {
      return null
    }
    const owner = createSolanaWallet(seed, !wallet.isSollet)
    return owner
  }
}

// Coin98 old format and sollet format
export function createSolanaWallet (seed, isSollet, privateKey) {
  if (privateKey) {
    return SolAccount.fromSecretKey(convertBase58(privateKey, true), {
      skipValidation: false
    })
  } else {
    const keyPair = nacl.sign.keyPair.fromSeed(
      isSollet ? derivePath(pathSollet, seed).key : seed.slice(0, 32)
    )
    const nodeSolana = new SolAccount(keyPair)
    return nodeSolana
  }
}

export async function signTransaction (transaction) {
  return window?.coin98?.sol
    .request({ method: 'sol_sign', params: [transaction] })
    .then((res) => {
      const sig = bs58.decode(res.signature)
      const publicKey = new PublicKey(res.publicKey)
      transaction.addSignature(publicKey, sig)
      return transaction
    })
    .catch((err) => {
      console.log({ err })
    })
}

export async function postBaseSendTxsNew (
  conn,
  transactions,
  signer,
  isWaitDone,
  callBack,
  callBackFinal,
  dataReturn
) {
  try {
    const rpc = get(window, 'walletServices.setting.transaction')
    const connection = genConnectionSolana(rpc)
    let action = 'sendTransaction'
    const isMobile = window.innerWidth < 576
    if (isMobile) {
      const { accountSol } = store.getState()

      const publicKey = new PublicKey(accountSol)
      transactions.feePayer = publicKey
      transactions.recentBlockhash = (
        await connection.getRecentBlockhash('max')
      ).blockhash

      transactions = await signTransaction(transactions)
      if (signer.length > 1) {
        const getSignerValid = signer.slice().filter((it) => it.secretKey)
        transactions.partialSign(...getSignerValid)
      }
      transactions = transactions.serialize()
      action = 'sendRawTransaction'
    }

    const tx = await connection[action](transactions, signer, {
      skipPreflight: false,
      preflightCommitment: 'confirmed'
    }).catch((err) => {
      console.log({ err })
      const data = JSON.stringify(get(err, 'logs', ''))
      return { isErr: true, data: encodeMessErr(data) }
    })
    const { isErr } = tx
    if (isErr) {
      return tx
    }
    callBack && callBack(tx, dataReturn)
    connection.onSignatureWithOptions(
      tx,
      async () => {
        if (isWaitDone) {
          callBackFinal && callBackFinal(tx, dataReturn)
        }
      },
      {
        commitment: 'confirmed'
      }
    )
    return tx
  } catch (err) {
    console.log('txs solana err: ', err)
    return { isErr: true, data: encodeMessErr(err) }
  }
}

export async function solanaGetBalance (address) {
  if (!address) return 0
  try {
    const connectionSolana = genConnectionSolana()
    const pubKey = new PublicKey(address)
    const balance = await connectionSolana.getBalance(pubKey)
    return convertWeiToBalance(balance, 9)
  } catch (error) {
    console.log({ error })
    return 0
  }
}

export async function findOldTokenAccount (owner, listAccount) {
  const tokenAccounts = {}
  const auxiliaryTokenAccounts = []
  const publicKey = new PublicKey(owner)
  for (const tokenAccountInfo of listAccount) {
    const tokenAccountPubkey = tokenAccountInfo.pubkey
    const tokenAccountAddress = tokenAccountPubkey.toBase58()
    const parsedInfo = tokenAccountInfo.account.data.parsed.info
    const mintAddress = parsedInfo.mint
    const balance = parsedInfo.tokenAmount.amount

    const ata = await TokenProgramService.findAssociatedTokenAddress(
      publicKey,
      new PublicKey(mintAddress)
    )

    if (mintAddress !== 'So11111111111111111111111111111111111111112') {
      if (ata.equals(tokenAccountPubkey)) {
        tokenAccounts[mintAddress] = {
          account: tokenAccountInfo.account,
          pubkey: new PublicKey(tokenAccountAddress),
          tokenAccountAddress,
          balance
        }
      } else if (parsedInfo && balance > 0) {
        tokenAccountInfo.ata = ata
        auxiliaryTokenAccounts.push(tokenAccountInfo)
      }
    }
  }

  return { owner, auxiliaryTokenAccounts, tokenAccounts }
}

export const getAllTokenSolanaByOwner = async ({
  address,
  isAllToken,
  tokenList,
  listAddressOwner
}) => {
  if (!address || isObject(address)) return []
  const balanceSOL = await getBalanceSol(address)
  if (getLength(listAddressOwner) > 0) {
    const listToken = tokenList || window.walletServices.tokenSolana
    const allAccount = listAddressOwner.map((token) => {
      const { mint, amount, address } = token
      const fMint = mint.toString()
      const findCoin = listToken.find(
        (dataCoin) => dataCoin.mintAddress === fMint
      )

      const coinDecimal = findCoin ? findCoin.decimals || 6 : 0

      const amountToken = parseFloat(convertWeiToBalance(amount, coinDecimal))

      if (amountToken > 0) {
        if (findCoin && findCoin.symbol !== 'SOL') {
          return {
            ...findCoin,
            address,
            balance: amountToken
          }
        }
        return isAllToken
          ? {
            address,
            balance: amountToken,
            mintAddress: fMint
          }
          : null
      }
    })
    const finalData = allAccount.filter((item) => item)
    return [{ ...NATIVE_SOL, balance: balanceSOL, address }, ...finalData]
  } else {
    return [{ ...NATIVE_SOL, balance: balanceSOL, address }]
  }
}

export const fetchMultipleAccount = async (accounts) => {
  const fetchAllData = await RPCSolana.buildRequest({
    bodyFetch: accounts,
    method: 'getMultipleAccounts'
  })
  if (!fetchAllData) return []
  const arrData = fetchAllData.map((data) => {
    const result = get(data, 'result', {})
    return get(result, 'value')
  })
  return arrData.flat()
}
