import images from 'assets/images'
import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import get from 'lodash/get'
import { formatNumberBro, showNotificationToast } from 'common/functions'
import { Icon } from 'components/Icon'

const ReviewSwapModal = ({
  mintToken,
  renderRate,
  renderPriceImpact,
  fee,
  fromCoinInput,
  toCoinInput,
  onCancel,
  onConfirm,
  uiFee,
  fromToken,
  toToken
}) => {
  const { t } = useTranslation()
  const [isSwapping, setIsSwapping] = useState(false)

  const handleSwap = async () => {
    setIsSwapping(true)
    await onConfirm()
    setIsSwapping(false)
  }

  return (
    <div>
      <div className={styles.reviewSwapOrder}>
        <div className="modal-top">
          {/* <img className="logo-slippage" src={images.logoReview} alt="" /> */}
          <Icon type="lg" name="saros_review_order" />
          <div className="modal-label text-xl">{t('reviewOrder')}</div>
        </div>

        <div className="d-flex align-items-center">
          <img className="coin-image" src={get(fromToken, 'icon')} alt="" />
          <div className="ml-3">
            <div className="color-grey text-sm">{t('youPay')}</div>
            <div className="text-xl uppercase">
              {formatNumberBro({ number: fromCoinInput, mantissa: 6 })}{' '}
              {get(fromToken, 'symbol')}
            </div>
          </div>
        </div>

        <div className="d-flex token-receive align-items-center">
          <img className="coin-image" src={get(toToken, 'icon')} alt="" />
          <div className="ml-3">
            <div className="color-grey text-sm">{t('youReceive')}</div>
            <div className="text-xl uppercase">
              {formatNumberBro({ number: toCoinInput, mantissa: 6 })}{' '}
              {get(toToken, 'symbol')}
            </div>
          </div>
        </div>

        <div className="info-wrapper">
          <div className="d-flex justify-content-between text-sm ">
            <div className="color-grey">{t('rate')}</div>
            <div className="uppercase">{renderRate}</div>
          </div>
          {uiFee
            ? (
              <div className="d-flex justify-content-between text-sm mt-2">
                <div className="color-grey">{t('uiFee')}</div>
                <div className="uppercase">
                  {parseFloat(uiFee) < 0.001
                    ? '< 0.001'
                    : formatNumberBro({ number: uiFee, mantissa: 6 })}{' '}
                  {get(toToken, 'symbol')}
                </div>
              </div>
            )
            : null}

          <div className="d-flex justify-content-between mt-2 text-sm">
            <div className="color-grey">{t('priceImpact')}</div>
            <div>{renderPriceImpact}</div>
          </div>
          <div className="d-flex justify-content-between mt-2 text-sm">
            <div className="color-grey">{t('swapFee')}</div>
            <div>{fee}</div>
          </div>
        </div>
        <div className="d-flex">
          <Button
            onClick={onCancel}
            type="grey"
            className="save-btn mr-2"
            isFullWidth
          >
            {t('cancel')}
          </Button>
          <Button
            isLoading={isSwapping}
            onClick={handleSwap}
            className="save-btn ml-2"
            isFullWidth
          >
            {t('confirm')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ReviewSwapModal
