import {
  Connection,
  Keypair,
  PublicKey,
  sendAndConfirmTransaction,
  Transaction,
  TransactionInstruction
} from '@solana/web3.js'
import BN from 'bn.js'
import { SolanaService } from 'common/pool/solanaService'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import {
  encodeMessErr,
  messFail,
  postBaseSendTxs,
  postBaseSendTxsNew
} from 'common/solana'
import { get } from 'lodash'
import { SarosFarmInstructionService } from './SarosFarmInstruction'

export class SarosFarmService {
  static async createPool (
    connection,
    payerAccount,
    poolName,
    stakingTokenMint,
    sarosFarmProgramAddress
  ) {
    const [poolAddress, poolNonce] = await this.findPoolAddress(
      poolName,
      sarosFarmProgramAddress
    )
    const [poolAuthorityAddress, poolAuthorityNonce] =
      await this.findPoolAuthorityAddress(poolAddress, sarosFarmProgramAddress)

    const transaction = new Transaction()

    const createPoolInstruction =
      SarosFarmInstructionService.createPoolInstruction(
        payerAccount.publicKey,
        poolAddress,
        Buffer.from(poolName),
        poolNonce,
        poolAuthorityNonce,
        stakingTokenMint,
        sarosFarmProgramAddress
      )

    transaction.add(createPoolInstruction)

    const poolStakingTokenAccountAddress =
      await TokenProgramService.findAssociatedTokenAddress(
        poolAuthorityAddress,
        stakingTokenMint
      )
    if (
      await SolanaService.isAddressAvailable(
        connection,
        poolStakingTokenAccountAddress
      )
    ) {
      const createATAInstruction =
        await TokenProgramInstructionService.createAssociatedTokenAccount(
          payerAccount.publicKey,
          poolAuthorityAddress,
          stakingTokenMint
        )
      transaction.add(createATAInstruction)
    }

    // const tx = await postBaseSendTxs(
    //   connection,
    //   [transaction],
    //   [payerAccount],
    //   true
    // )

    const tx = await sendAndConfirmTransaction(connection, transaction, [
      payerAccount
    ])

    console.info(`Created Pool ${poolAddress.toBase58()}`, '---', tx, '\n')
    return tx
  }

  static async stakePool (
    connection,
    payerAccount,
    poolAddress,
    userStakingTokenAddress,
    amount,
    sarosFarmProgramAddress,
    rewards = [],
    isMaxBalance,
    lpAddress,
    callBack,
    callBackFinal
  ) {
    try {
      const pool = await this.getPoolData(connection, poolAddress)
      const [userPoolAddress, userPoolNonce] = await this.findUserPoolAddress(
        payerAccount.publicKey,
        poolAddress,
        sarosFarmProgramAddress
      )

      const transaction = new Transaction()

      if (
        !userStakingTokenAddress ||
        (await SolanaService.isAddressAvailable(
          connection,
          userStakingTokenAddress
        ))
      ) {
        const createATAInstruction =
          await TokenProgramService.createAssociatedTokenAccount(
            payerAccount.publicKey,
            payerAccount.publicKey,
            lpAddress
          )
        transaction.add(createATAInstruction)
      }

      if (await SolanaService.isAddressAvailable(connection, userPoolAddress)) {
        const createUserPoolInstruction =
          SarosFarmInstructionService.createUserPoolInstruction(
            payerAccount.publicKey,
            poolAddress,
            userPoolAddress,
            userPoolNonce,
            sarosFarmProgramAddress
          )
        transaction.add(createUserPoolInstruction)
      }

      const stakePoolInstruction =
        SarosFarmInstructionService.stakePoolInstruction(
          poolAddress,
          pool.stakingTokenAccount,
          payerAccount.publicKey,
          userPoolAddress,
          userStakingTokenAddress,
          amount,
          sarosFarmProgramAddress
        )

      transaction.add(stakePoolInstruction)

      await Promise.all(
        rewards.map(async (reward) => {
          const { poolRewardAddress } = reward
          await this.stakePoolReward(
            connection,
            payerAccount,
            poolAddress,
            new PublicKey(poolRewardAddress),
            sarosFarmProgramAddress,
            transaction
          )
        })
      )

      const tx = await postBaseSendTxsNew(
        connection,
        transaction,
        [payerAccount],
        true,
        callBack,
        callBackFinal
      )
      const { isErr, data } = tx

      if (isErr) {
        return { isErr: true, data }
      }

      return { isErr: false, data: tx }
    } catch (err) {
      const logs = get(err, 'logs', '').toString()

      return { isErr: true, data: encodeMessErr(logs) }
    }
  }

  static async stakePoolReward (
    connection,
    payerAccount,
    poolAddress,
    poolRewardAddress,
    sarosFarmProgramAddress,
    transaction
  ) {
    const [userPoolAddress] = await this.findUserPoolAddress(
      payerAccount.publicKey,
      poolAddress,
      sarosFarmProgramAddress
    )
    const [userPoolRewardAddress, userPoolRewardNonce] =
      await this.findUserPoolRewardAddress(
        payerAccount.publicKey,
        poolRewardAddress,
        sarosFarmProgramAddress
      )

    if (
      await SolanaService.isAddressAvailable(connection, userPoolRewardAddress)
    ) {
      const createUserPoolRewardInstruction =
        SarosFarmInstructionService.createUserPoolRewardInstruction(
          payerAccount.publicKey,
          poolRewardAddress,
          userPoolRewardAddress,
          userPoolRewardNonce,
          sarosFarmProgramAddress
        )
      transaction.add(createUserPoolRewardInstruction)
    }

    const stakePoolRewardInstruction =
      SarosFarmInstructionService.stakePoolRewardInstruction(
        poolAddress,
        poolRewardAddress,
        payerAccount.publicKey,
        userPoolAddress,
        userPoolRewardAddress,
        sarosFarmProgramAddress
      )

    transaction.add(stakePoolRewardInstruction)

    console.info(
      `Staked to PoolReward ${poolRewardAddress.toBase58()}`,
      '---',
      // tx,
      '\n'
    )
    return transaction
  }

  static async claimReward (
    connection,
    payerAccount,
    poolRewardAddress,
    userPoolRewardAddress,
    userRewardTokenAddress,
    sarosFarmProgramAddress,
    mintAddress,
    callBack,
    callBackFinal
  ) {
    try {
      const [poolRewardAuthorityAddress] =
        await this.findPoolRewardAuthorityAddress(
          poolRewardAddress,
          sarosFarmProgramAddress
        )

      const dataPoolReward = await SarosFarmService.getPoolRewardData(
        connection,
        poolRewardAddress
      )

      const transaction = new Transaction()

      if (
        await SolanaService.isAddressAvailable(
          connection,
          userRewardTokenAddress
        )
      ) {
        const createATAInstruction =
          await TokenProgramService.createAssociatedTokenAccount(
            payerAccount.publicKey,
            payerAccount.publicKey,
            new PublicKey(mintAddress.toString())
          )
        transaction.add(createATAInstruction)
      }

      const claimRewardInstruction =
        SarosFarmInstructionService.claimRewardInstruction(
          poolRewardAddress,
          poolRewardAuthorityAddress,
          dataPoolReward.rewardTokenAccount,
          payerAccount.publicKey,
          userPoolRewardAddress,
          userRewardTokenAddress,
          sarosFarmProgramAddress
        )

      transaction.add(claimRewardInstruction)

      const tx = await postBaseSendTxsNew(
        connection,
        transaction,
        [payerAccount],
        true,
        callBack,
        callBackFinal
      )

      const { isErr, data } = tx

      if (isErr) {
        return { isErr: true, data }
      }

      return { isErr: false, data: tx }
    } catch (err) {
      const logs = get(err, 'logs', '').toString()

      return { isErr: true, data: encodeMessErr(logs) }
    }
  }

  static async claimAllReward (
    connection,
    payerAccount,
    sarosFarmProgramAddress,
    rewards = [],
    callBack,
    callBackFinal
  ) {
    try {
      const transaction = new Transaction()
      await Promise.all(
        rewards.map(async (reward) => {
          const { poolRewardAddress, mintAddress } = reward
          const [poolRewardAuthorityAddress] =
            await this.findPoolRewardAuthorityAddress(
              new PublicKey(poolRewardAddress.toString()),
              sarosFarmProgramAddress
            )

          const [userPoolRewardAddress] =
            await SarosFarmService.findUserPoolRewardAddress(
              payerAccount.publicKey,
              new PublicKey(poolRewardAddress),
              sarosFarmProgramAddress
            )

          const dataPoolReward = await SarosFarmService.getPoolRewardData(
            connection,
            new PublicKey(poolRewardAddress)
          )
          const userRewardTokenAccount =
            await TokenProgramService.findAssociatedTokenAddress(
              payerAccount.publicKey,
              new PublicKey(mintAddress.toString())
            )

          if (
            await SolanaService.isAddressAvailable(
              connection,
              new PublicKey(userRewardTokenAccount)
            )
          ) {
            const createATAInstruction =
              await TokenProgramService.createAssociatedTokenAccount(
                payerAccount.publicKey,
                payerAccount.publicKey,
                new PublicKey(mintAddress)
              )
            transaction.add(createATAInstruction)
          }

          const claimRewardInstruction =
            SarosFarmInstructionService.claimRewardInstruction(
              new PublicKey(poolRewardAddress),
              poolRewardAuthorityAddress,
              dataPoolReward.rewardTokenAccount,
              payerAccount.publicKey,
              userPoolRewardAddress,
              new PublicKey(userRewardTokenAccount),
              sarosFarmProgramAddress
            )

          transaction.add(claimRewardInstruction)
        })
      )

      const tx = await postBaseSendTxsNew(
        connection,
        transaction,
        [payerAccount],
        true,
        callBack,
        callBackFinal
      )

      const { isErr, data } = tx
      if (isErr) {
        return { isErr: true, data }
      }

      return { isErr: false, data: tx }
    } catch (err) {
      const logs = get(err, 'logs', '').toString()
      return { isErr: true, data: encodeMessErr(logs) }
    }
  }

  static async addTransactionClaimReward (
    connection,
    payerAccount,
    poolRewardAddress,
    userPoolRewardAddress,
    userRewardTokenAddress,
    sarosFarmProgramAddress,
    transaction
  ) {
    try {
      const [poolRewardAuthorityAddress] =
        await this.findPoolRewardAuthorityAddress(
          poolRewardAddress,
          sarosFarmProgramAddress
        )

      const dataPoolReward = await SarosFarmService.getPoolRewardData(
        connection,
        poolRewardAddress
      )

      const claimRewardInstruction =
        SarosFarmInstructionService.claimRewardInstruction(
          poolRewardAddress,
          poolRewardAuthorityAddress,
          dataPoolReward.rewardTokenAccount,
          payerAccount.publicKey,
          userPoolRewardAddress,
          userRewardTokenAddress,
          sarosFarmProgramAddress
        )

      transaction.add(claimRewardInstruction)

      return { isErr: false, data: transaction }
    } catch (err) {
      return { isErr: true, data: err }
    }
  }

  static async unstakePool (
    connection,
    payerAccount,
    poolAddress,
    userStakingTokenAddress,
    amount,
    sarosFarmProgramAddress,
    rewards = [],
    isMaxBalance,
    lpAddress,
    callBack,
    callBackFinal
  ) {
    try {
      const transaction = new Transaction()

      const pool = await this.getPoolData(connection, poolAddress)
      const [userPoolAddress] = await this.findUserPoolAddress(
        payerAccount.publicKey,
        poolAddress,
        sarosFarmProgramAddress
      )

      await Promise.all(
        rewards.map(async (reward) => {
          const { poolRewardAddress, userRewardTokenAccount } = reward

          // const [userPoolRewardAddress] =
          //   await SarosFarmService.findUserPoolRewardAddress(
          //     payerAccount.publicKey,
          //     new PublicKey(poolRewardAddress),
          //     sarosFarmProgramAddress
          //   )

          // await this.addTransactionClaimReward(
          //   connection,
          //   payerAccount,
          //   new PublicKey(poolRewardAddress),
          //   userPoolRewardAddress,
          //   new PublicKey(userRewardTokenAccount),
          //   sarosFarmProgramAddress,
          //   transaction
          // )

          await this.unstakePoolReward(
            connection,
            payerAccount,
            poolAddress,
            new PublicKey(poolRewardAddress),
            sarosFarmProgramAddress,
            transaction
          )
        })
      )

      const unstakePoolInstruction =
        SarosFarmInstructionService.unstakePoolInstruction(
          poolAddress,
          pool.authorityAddress,
          pool.stakingTokenAccount,
          payerAccount.publicKey,
          userPoolAddress,
          userStakingTokenAddress,
          amount,
          sarosFarmProgramAddress
        )
      transaction.add(unstakePoolInstruction)

      if (!isMaxBalance) {
        await Promise.all(
          rewards.map(async (reward) => {
            const { poolRewardAddress } = reward
            await this.stakePoolReward(
              connection,
              payerAccount,
              poolAddress,
              new PublicKey(poolRewardAddress),
              sarosFarmProgramAddress,
              transaction
            )
          })
        )
      }

      // const tx = await sendAndConfirmTransaction(connection, transaction, [
      //   payerAccount
      // ])

      const tx = await postBaseSendTxsNew(
        connection,
        transaction,
        [payerAccount],
        true,
        callBack,
        callBackFinal
      )
      const { isErr, data } = tx

      if (isErr) {
        return { isErr: true, data }
      }

      return { isErr: false, data: tx }
    } catch (err) {
      console.log({ err })
      const logs = get(err, 'logs', '').toString()

      return { isErr: true, data: encodeMessErr(logs) }
    }
  }

  static async unstakePoolReward (
    connection,
    payerAccount,
    poolAddress,
    poolRewardAddress,
    sarosFarmProgramAddress,
    transaction
  ) {
    const [userPoolAddress] = await this.findUserPoolAddress(
      payerAccount.publicKey,
      poolAddress,
      sarosFarmProgramAddress
    )
    const [userPoolRewardAddress] = await this.findUserPoolRewardAddress(
      payerAccount.publicKey,
      poolRewardAddress,
      sarosFarmProgramAddress
    )

    const unstakePoolRewardInstruction =
      SarosFarmInstructionService.unstakePoolRewardInstruction(
        poolAddress,
        poolRewardAddress,
        payerAccount.publicKey,
        userPoolAddress,
        userPoolRewardAddress,
        sarosFarmProgramAddress
      )

    transaction.add(unstakePoolRewardInstruction)

    return transaction
  }

  static async withdrawToken (
    connection,
    payerAccount,
    baseAddress,
    from,
    to,
    amount,
    sarosFarmProgramAddress
  ) {
    const [authority, nonce] = await this.findPoolAuthorityAddress(
      baseAddress,
      sarosFarmProgramAddress
    )
    const transaction = new Transaction()

    const withdrawTokenInstruction =
      SarosFarmInstructionService.withdrawTokenInstruction(
        payerAccount.publicKey,
        baseAddress,
        authority,
        nonce,
        from,
        to,
        amount,
        sarosFarmProgramAddress
      )

    transaction.add(withdrawTokenInstruction)

    const tx = await sendAndConfirmTransaction(connection, transaction, [
      payerAccount
    ])

    return tx
  }

  static async updatePoolRewardParams (
    connection,
    payerAccount,
    poolRewardAddress,
    rootRewardTokenAddress,
    poolRewardTokenAddress,
    newRewardPerBlock,
    newStartBlock,
    newEndBlock,
    sarosFarmProgramAddress
  ) {
    const transaction = new Transaction()

    const updatePoolRewardParamsInstruction =
      SarosFarmInstructionService.updatePoolRewardParamsInstruction(
        payerAccount.publicKey,
        poolRewardAddress,
        rootRewardTokenAddress,
        poolRewardTokenAddress,
        newRewardPerBlock,
        newStartBlock,
        newEndBlock,
        sarosFarmProgramAddress
      )

    transaction.add(updatePoolRewardParamsInstruction)

    const tx = await sendAndConfirmTransaction(connection, transaction, [
      payerAccount
    ])

    // const tx = await postBaseSendTxs(
    //   connection,
    //   [transaction],
    //   [payerAccount],
    //   true
    // )

    console.info(
      `Update pool reward params ${poolRewardAddress.toBase58()}`,
      '---',
      tx,
      '\n'
    )
    return tx
  }

  static async findPoolAuthorityAddress (poolAddress, sarosFarmProgramAddress) {
    return PublicKey.findProgramAddress(
      [Buffer.from('authority'), poolAddress.toBytes()],
      sarosFarmProgramAddress
    )
  }

  static async findPoolAddress (poolName, sarosFarmProgramAddress) {
    return PublicKey.findProgramAddress(
      [Buffer.from(poolName)],
      sarosFarmProgramAddress
    )
  }

  static async findPoolRewardAddress (
    poolAddress,
    rewardTokenMint,
    sarosFarmProgramAddress
  ) {
    return PublicKey.findProgramAddress(
      [poolAddress.toBytes(), rewardTokenMint.toBytes()],
      sarosFarmProgramAddress
    )
  }

  static async findPoolRewardAuthorityAddress (
    poolRewardAddress,
    sarosFarmProgramAddress
  ) {
    return PublicKey.findProgramAddress(
      [Buffer.from('authority'), poolRewardAddress.toBytes()],
      sarosFarmProgramAddress
    )
  }

  static async getPoolData (connection, poolAddress) {
    const accountInfo = await connection.getAccountInfo(
      poolAddress,
      'confirmed'
    )
    const data = SarosFarmInstructionService.decodePoolAccount(
      accountInfo.data
    )
    const [authorityAddress] = await this.findPoolAuthorityAddress(
      poolAddress,
      accountInfo.owner
    )
    data.authorityAddress = authorityAddress

    return data
  }

  static async findUserPoolAddress (
    ownerAddress,
    poolAddress,
    sarosFarmProgramAddress
  ) {
    return PublicKey.findProgramAddress(
      [ownerAddress.toBytes(), poolAddress.toBytes()],
      sarosFarmProgramAddress
    )
  }

  static async findUserPoolRewardAddress (
    ownerAddress,
    poolRewardAddress,
    sarosFarmProgramAddress
  ) {
    return PublicKey.findProgramAddress(
      [ownerAddress.toBytes(), poolRewardAddress.toBytes()],
      sarosFarmProgramAddress
    )
  }

  static async getPoolRewardData (connection, poolRewardAddress) {
    const accountInfo = await connection.getAccountInfo(
      poolRewardAddress,
      'confirmed'
    )

    const data = SarosFarmInstructionService.decodePoolRewardAccount(
      accountInfo.data
    )
    const [authorityAddress] = await this.findPoolRewardAuthorityAddress(
      poolRewardAddress,
      accountInfo.owner
    )

    data.authorityAddress = authorityAddress

    return data
  }

  static async getUserPoolAccountData (connection, userPoolAddress) {
    const accountInfo = await connection.getAccountInfo(
      userPoolAddress,
      'confirmed'
    )
    const hasData = get(accountInfo, 'data')
    if (!hasData) return ''
    const data = SarosFarmInstructionService.decodeUserPoolAccount(
      accountInfo.data
    )

    return data
  }

  static async getUserPoolRewardAccountData (connection, userPoolRewardAddress) {
    const accountInfo = await connection.getAccountInfo(
      userPoolRewardAddress,
      'confirmed'
    )
    const hasData = get(accountInfo, 'data')
    if (!hasData) return ''
    const data = SarosFarmInstructionService.decodeUserPoolRewardAccount(
      accountInfo.data
    )

    return data
  }

  static async printUserPoolInfo (connection, userPoolAddress) {
    const accountData = await SarosFarmService.getUserPoolAccountData(
      connection,
      userPoolAddress
    )
    console.info('--- USER POOL ACCOUNT INFO ---')

    console.info(
      `Address:       ${userPoolAddress.toBase58()} --- ${userPoolAddress
        .toBuffer()
        .toString('hex')}`
    )
    console.info(`Nonce:         ${accountData.nonce}`)
    console.info(`Amount:        ${accountData.amount.toString()}`)
    console.info(`Total Staked:  ${accountData.totalStaked.toString()}`)
  }

  static async printUserPoolRewardInfo (connection, userPoolAddress) {
    const accountData = await SarosFarmService.getUserPoolRewardAccountData(
      connection,
      userPoolAddress
    )
    console.info('--- USER POOL REWARD ACCOUNT INFO ---')

    console.info(
      `Address:        ${userPoolAddress.toBase58()} --- ${userPoolAddress
        .toBuffer()
        .toString('hex')}`
    )
    console.info(`Nonce:          ${accountData.nonce}`)
    console.info(`Amount:         ${accountData.amount.toString()}`)
    console.info(`Reward Debt:    ${accountData.rewardDebt.toNumber()}`)
    console.info(`Reward Pending: ${accountData.rewardPending.toNumber()}`)
  }
}
