import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { chunk, get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  fetchMultipleAccount,
  genConnectionSolana,
  genOwnerSolana
} from 'common/solana'
import BaseAPI from 'controller/api/BaseAPI'
import { PublicKey } from '@solana/web3.js'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'
import { SarosSwapInstructionService } from 'common/pool/saros_swap/sarosSwapIntructions'
import BaseAPINew from 'controller/api/BaseApiNew'
import { sarosSwapType } from 'common/constants'
import useFarmHook from 'hooks/farm/useFarmHook'
import { AppContext } from 'context/appContext'
import useLiquidity from 'hooks/liquidity/useLiquidity'
import { getLength } from 'common/functions'
import { useHistory } from 'react-router-dom'

const LiquidityContext = createContext()

const LiquidityProvider = ({ children, shortcut0Mint, shortcut1Mint }) => {
  const listToken = window.walletServices.tokenSolana
  const history = useHistory()
  const appContext = useContext(AppContext)

  const { listAccountOwner, isMobile } = appContext
  const { getPoolLiquidityExit } = useLiquidity({
    isFetchListToken: false
  })
  const farmActions = useFarmHook()
  const accountSol = useSelector((state) => state.accountSol)
  const isSession = useSelector((state) => state.isSession)

  const [isLoading, setIsLoading] = useState(true)
  const [listLiquidity, setListLiquidity] = useState([])
  const [dataPoolInfo, setDataPoolInfo] = useState(null)
  const [isFarming, setIsFarming] = useState(false)
  const [slippageValue, setSlippageValue] = useState(0.5)
  const [selectedDataPool, setSelectedDataPool] = useState(null)
  const [liquidityToken, setLiquidityToken] = useState({
    token0: listToken.find(
      (item) => get(item, 'mintAddress') === shortcut0Mint
    ),
    token1: listToken.find(
      (item) => get(item, 'mintAddress') === shortcut1Mint
    )
  })
  const [isLoadingDetailPool, setIsLoadingDetailPool] = useState(false)
  const [listFarm, setListFarm] = useState([])
  const [isNewPool, setIsNewPool] = useState(false)

  const isShortcut = useMemo(() => {
    return !get(history, 'location.pathname', '').includes('liquidity')
  }, [history])

  useEffect(() => {
    initDataNew()
  }, [listAccountOwner, isSession])

  useEffect(() => {
    const { token0, token1 } = liquidityToken
    const fromMint = get(token0, 'mintAddress')
    const toMint = get(token1, 'mintAddress')
    getInfoLiquidityByToken({ fromMint, toMint })
  }, [liquidityToken, isSession])

  const initDataNew = async () => {
    try {
      setIsLoading(true)

      const search = new URL(window.location.href).search
      const query = new URLSearchParams(search)
      const base = query.get('token0')
      const pair = query.get('token1')

      const fromCoin = listToken.find((token) => token.mintAddress === base)
      const toCoin = listToken.find((token) => token.mintAddress === pair)
      if (fromCoin && toCoin) {
        setLiquidityToken({
          token0: fromCoin,
          token1: toCoin
        })
      }
      if (isShortcut) return
      // fetch all list pool by wallet address onchain
      const response = await getListLiquidity()
      if (!response) {
        setIsLoading(false)
        setListLiquidity([])
        return []
      }

      setListLiquidity(response)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const getInfoLiquidityByToken = async ({ fromMint, toMint }) => {
    if (!fromMint || !toMint) {
      setDataPoolInfo(null)
      setSelectedDataPool(null)
      return null
    }
    setIsLoadingDetailPool(true)

    const dataPoolInfo = await getPoolLiquidityExit({ fromMint, toMint, dataPoolLiquidity: selectedDataPool })
    if (!dataPoolInfo) {
      setIsLoading(false)
      setDataPoolInfo(null)
      setSelectedDataPool(null)
      setIsLoadingDetailPool(false)
      setIsNewPool(true)

      return null
    }
    setDataPoolInfo(dataPoolInfo)
    setIsLoadingDetailPool(false)
    if (!accountSol) return
    const poolList = await getListLiquidity([dataPoolInfo]) || []

    const poolInList = poolList.find(
      (item) => get(item, 'id') === get(dataPoolInfo, 'id')
    )
    const newDataPool = {
      ...dataPoolInfo,
      ...poolInList
    }
    setDataPoolInfo(newDataPool)

    return newDataPool
  }

  const getListLiquidity = async (listPool) => {
    if (!accountSol || (!isMobile && !isSession)) return null

    const owner = await genOwnerSolana(accountSol)
    const listMintToken = listAccountOwner
      .filter((token) => get(token, 'decimals', 6) === 2)
      .map((item) => item.mint)
    const response =
      listPool ||
      (await BaseAPI.postData('saros/pool/lp', {
        arrLp: listMintToken
      }))

    if (!response) return []

    const listInfoLp = await Promise.all(
      response.map(async (pool) => {
        const poolAddress = new PublicKey(get(pool, 'id', '').toString())
        let lpMint = new PublicKey(
          get(pool, 'poolAccountInfo.lpTokenMint', '')
        )
        if (!lpMint) {
          lpMint = get(pool, 'poolAccountInfo.lpTokenMint', '').toString()
        }

        const lpAccount = await TokenProgramService.findAssociatedTokenAddress(
          owner.publicKey,
          lpMint
        )

        const token0Mint = get(pool, 'token0Mint', '').toString()
        const token1Mint = get(pool, 'token1Mint', '').toString()

        let token0Account = get(pool, 'poolAccountInfo.token0Account')
        let token1Account = get(pool, 'poolAccountInfo.token1Account')

        if (get(token0Account, 'mint')) {
          token0Account = await TokenProgramService.findAssociatedTokenAddress(
            owner.publicKey,
            new PublicKey(token0Mint)
          )
        }

        if (get(token1Account, 'mint')) {
          token1Account = await TokenProgramService.findAssociatedTokenAddress(
            owner.publicKey,
            new PublicKey(token1Mint)
          )
        }

        return [
          new PublicKey(token0Account.toString()),
          new PublicKey(token1Account.toString()),
          lpAccount,
          lpMint,
          poolAddress
        ]
      })
    )
    const listAccountLpByOwnerChunk = chunk(listInfoLp.flat(), 99)

    const getListDataLp = fetchMultipleAccount(listAccountLpByOwnerChunk)
    const getListFarmUserStaked = isShortcut ? [] : getListPoolFarm()

    const [fetchLisDataLp, listPoolFarm] = await Promise.all([
      getListDataLp,
      getListFarmUserStaked
    ])

    const newListPool = response.map((pool, index) => {
      const indexCircle = 5
      const indexToken0Account = indexCircle * index
      const indexToken1Account = indexToken0Account + 1
      const indexLpAccount = indexToken1Account + 1
      const indexLpMint = indexLpAccount + 1
      const indexPoolAddress = indexLpMint + 1
      const bufferToken0 = fetchLisDataLp[indexToken0Account]
      const bufferToken1 = fetchLisDataLp[indexToken1Account]
      const bufferLpAccount = fetchLisDataLp[indexLpAccount]
      const bufferLpMint = fetchLisDataLp[indexLpMint]
      const bufferPoolAddress = fetchLisDataLp[indexPoolAddress]

      let dataToken0Decode = null
      let dataToken1Decode = null
      let dataLpAccountDecode = null
      let dataLpMintDecode = null
      let dataPoolAddress = null

      if (bufferToken0) {
        const buffer = Buffer.from(get(bufferToken0, 'data[0]'), 'base64')
        dataToken0Decode =
          TokenProgramInstructionService.decodeTokenAccountInfo(buffer)
      }

      if (bufferToken1) {
        const buffer = Buffer.from(get(bufferToken1, 'data[0]'), 'base64')
        dataToken1Decode =
          TokenProgramInstructionService.decodeTokenAccountInfo(buffer)
      }

      if (bufferLpAccount) {
        const buffer = Buffer.from(get(bufferLpAccount, 'data[0]'), 'base64')

        dataLpAccountDecode =
          TokenProgramInstructionService.decodeTokenAccountInfo(buffer)
      }

      if (bufferLpMint) {
        const buffer = Buffer.from(get(bufferLpMint, 'data[0]'), 'base64')

        dataLpMintDecode =
          TokenProgramInstructionService.decodeTokenMintInfo(buffer)
      }

      if (bufferPoolAddress) {
        const buffer = Buffer.from(get(bufferPoolAddress, 'data[0]'), 'base64')

        dataPoolAddress = SarosSwapInstructionService.decodePoolData(buffer)
      }
      const dataFarmUserStaked = get(listPoolFarm, 'listFarmUserStaked', []).find(
        (item) => {
          const lpMintFarm = get(item, 'dataPoolInfo.mint', '').toString()
          const lpTokenMint = get(
            pool,
            'poolAccountInfo.lpTokenMint',
            ''
          ).toString()
          return lpMintFarm === lpTokenMint
        }
      )

      const listPoolFarmSort = get(listPoolFarm, 'listFarm', []).sort((a, b) => parseFloat(b.startBlock) - parseFloat(a.startBlock))

      const dataFarm = listPoolFarmSort.find((item) => {
        const lpMintFarm = get(item, 'dataPoolInfo.mint', '').toString()
        const lpTokenMint = get(
          pool,
          'poolAccountInfo.lpTokenMint',
          ''
        ).toString()
        return lpMintFarm === lpTokenMint
      })
      const poolAccountInfo = {
        ...pool.poolAccountInfo,
        ...dataLpMintDecode,
        ...dataPoolAddress,
        token0Account: dataToken0Decode,
        token1Account: dataToken1Decode
      }
      return {
        ...pool,
        isFaming: false,
        poolAccountInfo,
        poolFarm: dataFarm,
        userInfo: {
          ...dataLpAccountDecode,
          dataFarm: dataFarmUserStaked
        }
      }
    })

    return newListPool
  }

  const getListPoolFarm = async () => {
    try {
      let response = listFarm
      if (getLength(listFarm) <= 0) {
        response = await BaseAPINew.getData('saros/information', {
          size: 100
        })
        setListFarm(response)
      }

      if (!response) return

      const connection = genConnectionSolana()
      const currentBlock = await connection.getSlot()
      const arrTemp = await Promise.all(
        response.map(async (pool) => {
          const { isErr, data } = await farmActions.fetchDetailPool(
            pool,
            currentBlock
          )
          if (isErr) return []
          return data
        })
      )
      const listFarmUserStaked = await updateDataUserInfoInPool(arrTemp)
      return { listFarmUserStaked, listFarm: arrTemp }
    } catch (err) {
      return []
    }
  }

  const updateDataUserInfoInPool = async (pools) => {
    try {
      const newList = await farmActions.updateDataUserInfoInPool(pools)
      const listFarmUserStaked = newList.filter((farm) => {
        return get(farm, 'userInfo.amountUserStaked', 0) > 0
      })
      return listFarmUserStaked
    } catch (err) {
      return []
    }
  }

  const handleToggleFarming = () => {
    setIsFarming(!isFarming)
  }

  const listLiquidityFilter = useMemo(() => {
    if (listLiquidity) {
      const newList = listLiquidity
        .filter((item) => {
          const dataFarm = get(item, 'userInfo.dataFarm')
          return isFarming ? dataFarm : item
        })
        .sort((a, b) => {
          return (
            parseFloat(get(b, 'userInfo.amount', '0').toString()) -
            parseFloat(get(a, 'userInfo.amount', '0').toString())
          )
        })
      return newList
    }
    return []
  }, [isFarming, listLiquidity])

  const handlePushStateUrl = ({ token0Mint, token1Mint, typeLiquidity }) => {
    if (isShortcut) return
    if (!token0Mint || !token1Mint) {
      return window.history.pushState('', '', '/liquidity')
    }
    const objType = {
      [sarosSwapType.addLiquidiTy]: 'add',
      [sarosSwapType.removeLiquidity]: 'remove'
    }
    const type = get(objType, `${typeLiquidity}`, 'add')
    return window.history.pushState(
      '',
      '',
      `/liquidity/${type}?token0=${token0Mint}&token1=${token1Mint}`
    )
  }
  return (
    <LiquidityContext.Provider
      value={{
        isLoading,
        listToken,
        listLiquidity: listLiquidityFilter,
        setDataPoolInfo,
        initDataLiquidity: initDataNew,
        getListLiquidity,
        dataPoolInfo,
        handleToggleFarming,
        isFarming,
        handlePushStateUrl,
        slippageValue,
        setSlippageValue,
        liquidityToken,
        setLiquidityToken,
        isLoadingDetailPool,
        isShortcut,
        isNewPool,
        setSelectedDataPool
      }}
    >
      {children}
    </LiquidityContext.Provider>
  )
}

export { LiquidityContext, LiquidityProvider }
