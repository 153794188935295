import { LIST_FAVORITES, LIST_RECENT_SWAP } from 'common/constants'
import { getItemStorage, getLength, setItemStorage } from 'common/functions'
import ReduxServices from 'common/redux'
import BaseAdapter from 'controller/api/BaseAdapter'
import StoreActions from 'controller/redux/actions/storeActions'
import { get } from 'lodash'
import React, { createContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const AppContext = createContext()

const AppContextProvider = ({ children }) => {
  const isMobile = window && window.innerWidth < 576

  const dispatch = useDispatch()
  const accountSol = useSelector((state) => state.accountSol)

  const [isDarkMode, setIsDarkMode] = useState(
    getItemStorage('theme') === 'dark'
  )
  const [listAccountOwner, setListAccountOwner] = useState([])
  const [isWaitingUpdateJWT, setIsWaitingUpdateJWT] = useState(false)
  const [isLoadingDataAccountOwner, setIsLoadingDataAccountOwner] =
    useState(true)

  const [isOpenListTokenMobile, setIsOpenListTokenMobile] = useState(false)

  const [listFavoritePool, setListFavoritePool] = useState([])

  useEffect(() => {
    initModeTheme()
  }, [])

  useEffect(() => {
    handleGetAllAccountOwner()
    handleUpdateTokenWithWallet()
  }, [accountSol])

  const handleGetAllAccountOwner = async () => {
    const listAccount = await ReduxServices.getAllAddressByOwner(accountSol)
    setListAccountOwner(listAccount)
    setIsLoadingDataAccountOwner(false)
    return listAccount
  }

  const getListFavoriteToken = async () => {
    const res = await BaseAdapter.getData('favorite', {
      type: 'sarosToken',
      version: '2'
    })
    if (res && res.success) {
      const listFavToken = get(res, 'data[0].bonusValue', [])
      if (getLength(listFavToken) === 0) {
        dispatch(StoreActions.setListFavoriteTokenRedux(LIST_FAVORITES))
      } else dispatch(StoreActions.setListFavoriteTokenRedux(listFavToken))
    } else dispatch(StoreActions.setListFavoriteTokenRedux(LIST_FAVORITES))
  }

  const getCurrentSwapList = async () => {
    const res = await BaseAdapter.getData('favorite', {
      type: 'sarosRecentSwap',
      version: '2'
    })
    if (res && res.success) {
      const listRecentSwap = get(res, 'data[0].bonusValue', [])
      if (getLength(listRecentSwap) === 0) {
        dispatch(StoreActions.setRecentSwapRedux(LIST_RECENT_SWAP))
      } else dispatch(StoreActions.setRecentSwapRedux(listRecentSwap))
    }
  }

  const getListFavoritePool = async () => {
    const res = await BaseAdapter.getData('favorite', {
      type: 'sarosPool',
      version: '2'
    })
    if (res && res.success) {
      const listFavPool = get(res, 'data[0].bonusValue', [])
      if (getLength(listFavPool) === 0) {
        dispatch(StoreActions.setListFavoritePoolRedux([]))
      } else dispatch(StoreActions.setListFavoritePoolRedux(listFavPool))
    } else dispatch(StoreActions.setListFavoritePoolRedux([]))
  }

  const fetchListPinnedFavoritePool = async () => {
    const response = await BaseAdapter.getData('saros/pool/filter', {
      page: 1,
      size: 20,
      type: 'favorite'
    })
    if (!get(response, 'success', false)) return setListFavoritePool([])
    const newList = get(response, 'data.data', [])
    setListFavoritePool(newList)
  }

  const handleUpdateTokenWithWallet = () => {
    ReduxServices.createTokenWithWallet(accountSol).then(res => {
      getListFavoritePool()
      getListFavoriteToken()
      getCurrentSwapList()
      fetchListPinnedFavoritePool()
    })
  }

  const initModeTheme = () => {
    if (isDarkMode) {
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('data-theme', 'dark')
      dispatch(StoreActions.setThemeRedux('dark'))
      setItemStorage('dark', 'theme')
    } else {
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('data-theme', 'light')
      dispatch(StoreActions.setThemeRedux('light'))
      setItemStorage('light', 'theme')
    }
  }

  const handleToggleMode = () => {
    if (isDarkMode) {
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('data-theme', 'light')
      dispatch(StoreActions.setThemeRedux('light'))
      setItemStorage('light', 'theme')
    } else {
      document
        .getElementsByTagName('HTML')[0]
        .setAttribute('data-theme', 'dark')
      dispatch(StoreActions.setThemeRedux('dark'))
      setItemStorage('dark', 'theme')
    }
    setIsDarkMode(!isDarkMode)
  }

  return (
    <AppContext.Provider
      value={{
        handleToggleMode,
        isDarkMode,
        listAccountOwner,
        handleGetAllAccountOwner,
        isLoadingDataAccountOwner,
        isOpenListTokenMobile,
        setIsOpenListTokenMobile,
        listFavoritePool,
        setListFavoritePool,
        isMobile
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContextProvider, AppContext }
