import { random } from 'common/functions'
import { get } from 'lodash'

export default class RPCSolana {
  static async buildRequest ({ bodyFetch, method }) {
    try {
      if (!bodyFetch.length) return null
      const body = JSON.stringify(
        bodyFetch.map((item) => ({
          method,
          params: [item, { commitment: 'singleGossip' }],
          id: random(),
          jsonrpc: '2.0'
        }))
      )
      // const rpc = get(window, 'walletServices.setting.fetch')
      const rpc = 'https://information.coin98.com/api/solanaV5'
      const response = await fetch(rpc, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authority: 'coin98.com',
          Version: process.env.REACT_APP_HUB_VERSION,
          Authorization: 'Bearer token',
          development: 'coin98',
          Signature:
            'c26340d5243d802f03de751b9cbc049557ad0a14296aacf4a37dc7399adbe65c'
        },
        body
      })

      if (response.ok) {
        return response.json()
      } else {
        return null
      }
    } catch (err) {
      return null
    }
  }
}
