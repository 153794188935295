/* eslint-disable no-undef */
import BaseAPI from 'controller/api/BaseAPI'
import { KEY_STORE } from 'common/constants/keystore'
import {
  convertWeiToBalance,
  formatBilion,
  formatNumberBro,
  getItemStorage,
  getLength,
  nFormatter,
  removeItemStorage,
  setItemStorage
} from './functions'
import Web3Services from 'controller/api/web3'
import { DeviceUUID } from 'device-uuid'
import { store } from 'controller/redux/store/configureStore'
import StoreActions from 'controller/redux/actions/storeActions'
import { chainType, CURRENCY_SYMBOL, SOLANA_TOKEN_LOCAL } from './constants'
import initState from 'controller/redux/lib/initState'
import get from 'lodash/get'
import BaseAdapter from 'controller/api/BaseAdapter'
import { genConnectionSolana, getBalanceSol } from './solana'
import { PublicKey } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { TokenProgramInstructionService } from './pool/tokenProgramInstructionService'
import io from 'socket.io-client'

let socketService

export default class ReduxServices {
  static async resetRedux () {
    const storageRedux = [
      { action: StoreActions.setConnected, init: initState.boolFalse },
      { action: StoreActions.setBalanceSol, init: initState.numberZero },
      { action: StoreActions.setAccountSol, init: initState.stringEmpty },
      { action: StoreActions.setWalletRedux, init: initState.arrayEmpty }

    ]
    storageRedux.forEach((itm) => {
      this.callDispatchAction(itm.action(itm.init))
    })
    removeItemStorage(KEY_STORE.PASSWORD_LOCK)
  }

  static async refreshAllData () {
    await this.createTempUser()
  }

  static async fetchDataSol ({ address, isMobile }) {
    try {
      let addressSol = address
      if (isMobile && !address) {
        const response = await window?.coin98?.sol.request({
          method: 'sol_accounts'
        })
        addressSol = response[0]
      }
      if (
        addressSol &&
        addressSol !== 'noConnectWallet' &&
        !addressSol.startsWith('0x')
      ) {
        this.callDispatchAction(StoreActions.setAccountSol(addressSol))
        this.callDispatchAction(StoreActions.setConnected(true))
        return { address: addressSol }
      }
      this.callDispatchAction(StoreActions.setAccountSol(null))
      this.callDispatchAction(StoreActions.setBalanceSol(0))
      this.callDispatchAction(StoreActions.setConnected(false))
      return null
    } catch (err) {
      return null
    }
  }

  static async getAllAddressByOwner (addressWallet) {
    try {
      if (!addressWallet) {
        return []
      }
      getBalanceSol()
      const listToken = window.walletServices.tokenSolana

      const connection = genConnectionSolana()
      const accountOwner = await connection.getTokenAccountsByOwner(
        new PublicKey(addressWallet.toString()),
        { programId: TOKEN_PROGRAM_ID }
      )
      if (getLength(accountOwner.value) > 0) {
        const allInfo = accountOwner.value.map((token) => {
          const infoAccount =
          TokenProgramInstructionService.decodeTokenAccountInfo(
            get(token, 'account.data')
          )
          const mint = get(infoAccount, 'mint', '').toString()
          const amount = get(infoAccount, 'amount', '').toString()
          const owner = get(infoAccount, 'owner', '').toString()
          const address = get(token, 'pubkey', '').toString()
          const info = listToken.find(item => get(item, 'mintAddress') === mint)
          return {
            mint,
            amount,
            owner,
            address,
            ...info
          }
        })
        return allInfo
      }
      return []
    } catch (err) {
      console.log({ err })
      return []
    }
  }

  static async createTempUser () {
    const JWT_TOKEN = getItemStorage(KEY_STORE.JWT_TOKEN)
    if (!JWT_TOKEN) {
      const deviceID = new DeviceUUID().get()
      const response = await BaseAdapter.postData('user/device', {
        device: deviceID
      })
      if (response) {
        const challenge = get(response, 'data.challenge')
        setItemStorage(challenge, KEY_STORE.JWT_TOKEN)
      }
    }
  }

  static async createTokenWithWallet (walletAddress) {
    const JWT_TOKEN_ADAPTER = getItemStorage(KEY_STORE.JWT_TOKEN_ADAPTER)
    if (get(JWT_TOKEN_ADAPTER, 'walletAddress') !== walletAddress) {
      const deviceID = new DeviceUUID().get()
      const response = await BaseAdapter.postData('user/device', {
        device: walletAddress || deviceID
        // chain: chainType.solana
      })
      if (response) {
        const code = get(response, 'data.code')
        setItemStorage({ token: code, walletAddress }, KEY_STORE.JWT_TOKEN_ADAPTER)
        return { token: code, walletAddress }
      }
    }
  }

  static convertToFiat (number, decimals = 2, isBilion, isNoCurrency = false) {
    const currSymbol = CURRENCY_SYMBOL.USD
    let numberFiat = number

    if (isBilion) {
      numberFiat = formatBilion(number, 0)
    } else {
      numberFiat = formatNumberBro({ number: numberFiat, mantissa: decimals })
    }
    if (isNoCurrency) return numberFiat

    return currSymbol + numberFiat
  }

  static async getOnesignalId () {
    if (window.OneSignal) {
      const userId = await window.OneSignal.getUserId()
      return userId
    } else {
      return null
    }
  }

  static async refreshNotification () {
    try {
      const webDeviceId = await getOnesignalId()

      await BaseAPI.postData('device', { id: webDeviceId.userId })
    } catch (e) {
      console.log('Notification error')
    }
  }

  static async callDispatchAction (action) {
    store.dispatch(action)
  }

  static getCurrencyPrice = (price) => {
    const storeState = store.getState()
    const currencyRedux = get(storeState, 'currencyRedux')
    const currencyRate = get(currencyRedux, 'value', 1)

    return Math.abs(price * currencyRate)
  }

  static formatPrice = (
    price,
    isLarge = false,
    mantissa
  ) => {
    const absPrice = Math.abs(price)

    // const currency = getItemStorage('CURRENCY')

    // const currencyRedux = useSelector(state => state.currencyRedux)
    const storeState = store.getState()
    const currencyRedux = get(storeState, 'currencyRedux')

    const currencyIcon = CURRENCY_SYMBOL[get(currencyRedux, 'label', 'USD')]
    const currencyRate = get(currencyRedux, 'value', 1)

    const isBTC = currencyIcon === 'BTC'
    const isVND = get(currencyRedux, 'label') === 'VND'

    let decimal = 2

    if (absPrice < 0.01) {
      decimal = 4
    }

    if (absPrice >= 0.01 && absPrice < 1) {
      decimal = 4
    }

    if (absPrice >= 1) {
      decimal = 2
    }

    if (isBTC) {
      decimal = 8
    }

    if (isLarge) {
      decimal = 2
    }

    if (price === 0) {
      decimal = 0
    }

    if (mantissa !== undefined) {
      decimal = mantissa
    }

    if (isVND) {
      decimal = 0
    }

    const threshholdPrice = isLarge ? 1000 : 100000000000

    const renderPrice = Math.abs(price * currencyRate) > threshholdPrice
      ? nFormatter(Math.abs(price * currencyRate), decimal)
      : formatNumberBro({
        number: Math.abs(price * currencyRate),
        mantissa: decimal,
        trimMantissa: true
      })

    return isVND
      ? renderPrice + currencyIcon
      : currencyIcon + renderPrice
  }

  static async activeSocket () {
    const jwtToken = getItemStorage(KEY_STORE.JWT_TOKEN)
    try {
      if (socketService) {
        socketService.removeAllListeners()
        socketService.disconnect()
        socketService = null
      }

      if (jwtToken) {
        setTimeout(() => {
          socketService = io(process.env.REACT_APP_SOCKET, {
            transports: ['websocket']
          })

          socketService.on('connect', async (e) => {
            socketService.emit('authentication_saros', jwtToken)
          })
        }, 500)
      }
    } catch (error) {
      //   this.activesocket(true)
    }
  }
}
