import images from 'assets/images'
import React, { useContext } from 'react'
import './style.scss'
import { AppContext } from 'context/appContext'
import { useTranslation } from 'react-i18next'
import ButtonConnected from 'components/common/ButtonConnected'
import { Icon } from 'components/Icon'
import useScreenWidth from 'hooks/useScreenWidth'

const Footer = () => {
  const appContext = useContext(AppContext)

  const { isDarkMode, handleToggleMode } = appContext

  const { width } = useScreenWidth()
  const isMobile = width < 500

  const listFooter = [
    {
      name: 'Docs',
      isComing: false,
      link: 'https://docs.saros.finance/'
    },
    {
      name: 'Blog',
      isComing: false,
      link: 'https://blog.saros.finance/'
    },
    {
      name: 'Twitter',
      isComing: false,
      link: 'https://twitter.com/Saros_Finance'
    },
    {
      name: 'Telegram',
      isComing: false,
      link: 'https://t.me/saros_finance'
    },
    {
      name: 'Discord',
      isComing: false,
      link: 'https://saros.link/discord'
    }
    // {
    //   name: 'Brand Assets',
    //   isComing: false,
    //   link: 'https://saros.link/logo'
    // }
  ]

  if (isMobile) {
    return (
      <div className='mobile-footer'>
        <div
          onClick={handleToggleMode}
          className="menu-item menu-item__them-mode"
        >
          <img src={images[isDarkMode ? 'iconMoon' : 'iconSun']} alt="" className="icon-theme"/>
        </div>

        <div className="header__connect-mobile">
          <ButtonConnected />
        </div>
      </div>
    )
  }
  return (
    <div className="footer">
      <div className="footer__logo ">
        <img
          className="logo-footer"
          src={images[isDarkMode ? 'iconSarosWhite' : 'iconSarosBlack']}
          alt=""
        />
        <span className='text-sm semibold ml-2'>© 2022, All Rights Reserved</span>
      </div>
      <div className="footer__list">
        {listFooter.map((item, index) => (
          <a
            href={item.link}
            target="_blank"
            key={index}
            className={`item ${!item.link && 'item--disabled'}`} rel="noreferrer"
          >
            {item.name}

            {item.isComing && (
              <div className="tooltip-coming">
                <div className="tooltip-coming__content">
                  <div className="trigle-up"></div>
                  Coming Soon
                </div>
              </div>
            )}
          </a>
        ))}
      </div>
    </div>
  )
}

export default Footer
