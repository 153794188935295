import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './styles/global.scss'
import './i18n'
import { Provider } from 'react-redux'
import { store, persistor } from 'controller/redux/store/configureStore'
import { PersistGate } from 'redux-persist/integration/react'
import WalletServices from 'common/WalletServices'
import 'normalize.css'
import { AppContextProvider } from 'context/appContext'

window.walletServices = new WalletServices()

const renderer = async () => {
  await Promise.all([
    window.walletServices.fetchSetting(),
    window.walletServices.refreshFetchData(true),
    window.walletServices.fetchTokenSolana()
  ])

  return render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

renderer()

serviceWorker.unregister()
