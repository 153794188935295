import { Icon } from 'components/Icon'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styles from './style.module.scss'
import cn from 'clsx'
import get from 'lodash/get'
import { getLength, upperCaseFirstLetter } from 'common/functions'
import Tooltip from 'components/Tooltip'
const SOCICAL_ICON = {
  twitter: 'twitter_filled',
  telegram: 'telegram_filled',
  link: 'link_filled'
}
const SocialNetworkLink = ({ className, listSocial }) => {
  const formatList = useMemo(() => {
    if (getLength(listSocial) === 0) return []
    return listSocial?.map(item => {
      const title = get(item, 'title')
      return {
        title: title,
        link: get(item, 'value', '#'),
        icon: SOCICAL_ICON[title]
      }
    })
  }, [listSocial])

  return (
    <div className={cn(styles.socialContainer, className)}>
      {formatList.map(item => (
        <Tooltip
          key={item.icon}
          content={upperCaseFirstLetter(item.title)}
        >
          <a key={item.icon} href={item.link} target="_blank" rel="noreferrer">
            <Icon backgroundClassName='icon-container' onClick={() => {}} name={item.icon} className="icon-social"/>
          </a>
        </Tooltip>
      ))}
    </div>
  )
}

export default SocialNetworkLink
