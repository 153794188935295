import React from 'react'
import cx from 'classnames'
import styles from './style.module.scss'

const ItemTable = ({ data, columns }) => {
  return (
    <>
      {columns.map((col) => {
        if (col.hide) return null
        return (
          <td
          // style={{ width: `${col.width}%` }}
            key={col.dataIndex}
            className={cx(
              styles['item-table'],
              col.className,
              col.align && styles[`item-table-ancient__item--${col.align}`]
            )}
          >
            {col.render ? col.render(data[`${col.dataIndex}`]) : data[`${col.dataIndex}`]}
          </td>
        )
      }
      )}
    </>
  )
}

export default ItemTable
