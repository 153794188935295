import { Transaction, PublicKey } from '@solana/web3.js'
import { CusdFactoryInstructionService } from './cusdInstructionsService'

// import { messFail, postBaseSendTxsNew } from 'walletservices'
import {
  postBaseSendTxsNew
} from 'common/solana'
import { SolanaService } from 'common/pool/solanaService'
import { HashService } from 'common/pool/hashService'
import { TokenProgramService } from 'common/pool/tokenProgramService'
import { TokenProgramInstructionService } from 'common/pool/tokenProgramInstructionService'

export const NAME_MINTER = 'CUSD_COIN98_3'
export const NAME_BURNER = 'CUSD_COIN98_BURNER_4'

export const CUSD_MINT = new PublicKey(
  'CUSDvqAQLbt7fRofcmV2EXfPA2t36kzj7FjzdmqDiNQL'
)

export const CUSD_PROGRAM_ID = new PublicKey(
  'CDMBw8drd8Ypct1YDTyJiUHN3KqP9tqtJJxjym5p3muQ'
)

export const CHAIN_LINK_PROGRAM = new PublicKey(
  'HEvSKofvBgfaexv23kMabbYqxasxU3mQ4ibBMEmJWHny'
)

export const MINTER_ADDRESS = new PublicKey(
  '8NhmbYs233suAAzUymhZzr46iy8NiyshAwWzQtmRxQNA'
)

export const BURNER_ADDRESS = new PublicKey(
  'ApByhEs5uDXW8tEuqLBt8MQ9f1SK2APxYnfaYT6U2vxq'
)

export const InputTokenParams = {
  tokenAddress: new PublicKey('USDThRLHuGVe8FQct1q7eV61eWoBGqX27DaVYZJQG7u'),
  decimals: 6,
  percentage: 10000,
  priceFeedAddress: new PublicKey(
    '4NmRgDfAZrfBHQBuzstMP5Bu1pgBzVn8u1djSvNrNkrN'
  )
}

export const OutputTokenParams = {
  tokenAddress: new PublicKey('USDThRLHuGVe8FQct1q7eV61eWoBGqX27DaVYZJQG7u'),
  decimals: 6,
  priceFeedAddress: new PublicKey(
    '4NmRgDfAZrfBHQBuzstMP5Bu1pgBzVn8u1djSvNrNkrN'
  )
}

export class CusdFactoryService {
  static async mint (
    addressWallet,
    connection,
    payerAccount,
    minterAddress, //  ???
    cusdTokenMintAddress, //  ???
    amount,
    chainlinkProgramId, //  ???
    cusdFactoryProgramId
  ) {
    try {
      const transaction = new Transaction()

      const [
        rootSignerAddress
      ] = CusdFactoryInstructionService.findRootSignerAddress(
        cusdFactoryProgramId
      )
      const minterInfo = await this.getMinterAccountInfo(
        connection,
        minterAddress
      )
      const userCusdTokenAddress = await TokenProgramService.findAssociatedTokenAddress(
        payerAccount.publicKey,
        cusdTokenMintAddress
      )

      if (
        await SolanaService.isAddressAvailable(connection, userCusdTokenAddress)
      ) {
        const createATAInstruction = await TokenProgramInstructionService.createAssociatedTokenAccount(
          payerAccount.publicKey,
          payerAccount.publicKey,
          cusdTokenMintAddress
        )
        transaction.add(createATAInstruction)
      }
      const inputTokens = await Promise.all(
        minterInfo.inputTokens.map(async (tokenMintAddress, index) => {
          const priceFeedAddress = minterInfo.inputPriceFeeds[index]
          const poolTokenAddress = await TokenProgramService.findAssociatedTokenAddress(
            rootSignerAddress,
            tokenMintAddress
          )
          const userTokenAddress = await TokenProgramService.findAssociatedTokenAddress(
            payerAccount.publicKey,
            tokenMintAddress
          )

          if (
            await SolanaService.isAddressAvailable(connection, poolTokenAddress)
          ) {
            const createATAInstruction = await TokenProgramInstructionService.createAssociatedTokenAccount(
              payerAccount.publicKey,
              rootSignerAddress,
              tokenMintAddress
            )
            transaction.add(createATAInstruction)
          }

          if (
            await SolanaService.isAddressAvailable(connection, userTokenAddress)
          ) {
            const createATAInstruction = await TokenProgramInstructionService.createAssociatedTokenAccount(
              payerAccount.publicKey,
              payerAccount.publicKey,
              tokenMintAddress
            )
            transaction.add(createATAInstruction)
          }

          return {
            priceFeedAddress,
            poolTokenAddress,
            userTokenAddress
          }
        })
      )

      const mintInstruction = CusdFactoryInstructionService.mint(
        payerAccount.publicKey,
        minterAddress,
        cusdTokenMintAddress,
        inputTokens,
        amount,
        userCusdTokenAddress,
        chainlinkProgramId,
        cusdFactoryProgramId
      )
      transaction.add(mintInstruction)

      const txSign = await postBaseSendTxsNew(
        connection,
        transaction,
        [payerAccount],
        true
      )

      return txSign
    } catch (err) {
      return { isErr: true, data: err }
    }
  }

  static async burn (
    addressWallet,
    connection,
    payerAccount,
    burnerAddress,
    cusdTokenMintAddress,
    amount,
    chainlinkProgramId,
    cusdFactoryProgramId
  ) {
    try {
      const [
        rootSignerAddress
      ] = CusdFactoryInstructionService.findRootSignerAddress(
        cusdFactoryProgramId
      )
      const burnerInfo = await this.getBurnerAccountInfo(
        connection,
        burnerAddress
      )
      const transaction = new Transaction()

      const poolTokenAddress = await TokenProgramService.findAssociatedTokenAddress(
        rootSignerAddress,
        burnerInfo.outputToken
      )

      const userTokenAddress = await TokenProgramService.findAssociatedTokenAddress(
        payerAccount.publicKey,
        burnerInfo.outputToken
      )

      if (await SolanaService.isAddressAvailable(connection, userTokenAddress)) {
        const createATAInstruction = await TokenProgramInstructionService.createAssociatedTokenAccount(
          payerAccount.publicKey,
          payerAccount.publicKey,
          burnerInfo.outputToken
        )
        transaction.add(createATAInstruction)
      }

      const outputToken = {
        priceFeedAddress: burnerInfo.outputPriceFeed,
        poolTokenAddress,
        userTokenAddress
      }
      const userCusdTokenAddress = await TokenProgramService.findAssociatedTokenAddress(
        payerAccount.publicKey,
        cusdTokenMintAddress
      )
      if (
        await SolanaService.isAddressAvailable(connection, userCusdTokenAddress)
      ) {
        const createATAInstruction = await TokenProgramInstructionService.createAssociatedTokenAccount(
          payerAccount.publicKey,
          payerAccount.publicKey,
          cusdTokenMintAddress
        )
        transaction.add(createATAInstruction)
      }

      const poolCusdTokenAddress = await TokenProgramInstructionService.findAssociatedTokenAddress(
        rootSignerAddress,
        cusdTokenMintAddress
      )

      if (
        await SolanaService.isAddressAvailable(connection, poolCusdTokenAddress)
      ) {
        const createATAInstruction = await TokenProgramInstructionService.createAssociatedTokenAccount(
          payerAccount.publicKey,
          rootSignerAddress,
          cusdTokenMintAddress
        )
        transaction.add(createATAInstruction)
      }
      const burnInstruction = CusdFactoryInstructionService.burn(
        poolCusdTokenAddress,
        payerAccount.publicKey,
        burnerAddress,
        cusdTokenMintAddress,
        userCusdTokenAddress,
        amount,
        outputToken,
        chainlinkProgramId,
        cusdFactoryProgramId
      )
      transaction.add(burnInstruction)

      const txSign = await postBaseSendTxsNew(
        connection,
        transaction,
        [payerAccount],
        true
      )
      return txSign
    } catch (err) {
      return { isErr: true, data: err }
    }
  }

  static async getBurnerAccountInfo (connection, burnerAddress) {
    const accountInfo = await connection.getAccountInfo(burnerAddress)
    const data = CusdFactoryInstructionService.decodeBurnerData(
      accountInfo.data
    )
    return data
  }

  static findMinterAddress (params, cusdFactoryProgramId) {
    const derivationPath =
      typeof params === 'string'
        ? HashService.sha256(params).slice(0, 8)
        : params
    return CusdFactoryInstructionService.findMinterAddress(
      derivationPath,
      cusdFactoryProgramId
    )
  }

  static findBurnerAddress (derivationPath, cusdFactoryProgramId) {
    return PublicKey.findProgramAddressSync(
      [HashService.sha256('Burner').slice(0, 8), derivationPath],
      cusdFactoryProgramId
    )
  }

  static async getMinterAccountInfo (connection, minterAddress) {
    const accountInfo = await connection.getAccountInfo(minterAddress)
    const data = CusdFactoryInstructionService.decodeMinterData(
      accountInfo.data
    )
    return data
  }
}
