import React, { useMemo, useState } from 'react'
import styles from './style.module.scss'
import cn from 'clsx'
import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { upperCaseFirstLetter } from 'common/functions'
import images from 'assets/images'
import Button from 'components/common/Button'
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton
} from 'react-share'
import BannerImage from 'components/common/BannerImage'

// const SOCICAL_ICON = {
//   twitter: 'twitter_filled',
//   telegram: 'telegram_filled',
//   link: 'link_filled'
// }

const ShareModal = ({
  type,
  poolAddress,
  token0 = {},
  token1 = {},
  campDetail = {}
}) => {
  const { t } = useTranslation()
  const [isCopying, setIsCopying] = useState(false)

  const shareLink = useMemo(() => {
    if (type === 'swap') {
      return `${window.location.origin}/${type}?base=${token0.mintAddress}&pair=${token1.mintAddress}`
    }

    if (type === 'snapshot') {
      return `${window.location.origin}/${type}/${campDetail.id}`
    }
    return `${window.location.origin}/${type}?poolAddress=${poolAddress}`
  }, [token0, token1, type, poolAddress, campDetail])

  const shareImgLink = useMemo(() => {
    if (type === 'snapshot') {
      return campDetail.image
    }
    if (type !== 'stake') {
      return `${process.env.REACT_APP_SUPPORT_API}sarosCard?type=${type}&token1=${token0.mintAddress}&token2=${token1.mintAddress}`
    }
    return `${process.env.REACT_APP_SUPPORT_API}sarosCard?type=${type}&token1=${token0.mintAddress}`
  }, [type, token0, token1, campDetail])

  const handleCopyText = (e) => {
    e.stopPropagation()
    e.preventDefault()
    window.navigator.clipboard.writeText(shareLink)
    setIsCopying(true)
  }

  const handleHoverOutCopy = () => {
    setTimeout(() => {
      setIsCopying(false)
    }, 500)
  }

  return (
    <div className={styles.shareModalContainer}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <span>{t(`share${upperCaseFirstLetter(type)}`)}</span>
        <Icon
          name="web_close"
          onClick={() => {
            window.closeModal()
          }}
        />
      </div>
      <div className="preview-image">
        <BannerImage src={shareImgLink} className="preview-image__bg" />
        {/* <img className='preview-image__bg' src={shareImgLink} alt="" /> */}
      </div>

      <div className="share-social">
        <TelegramShareButton url={shareLink}>
          <div className="share-social__item">
            <Icon name="telegram_filled" className="icon" />
          </div>
        </TelegramShareButton>

        <TwitterShareButton url={shareLink}>
          <div className="share-social__item">
            <Icon name="twitter_filled" className="icon" />
          </div>
        </TwitterShareButton>

        <FacebookShareButton url={shareLink}>
          <div className="share-social__item">
            <Icon name="facebook" className="icon" />
          </div>
        </FacebookShareButton>
      </div>

      <div className="link-container">
        {/* <a href={shareLink} className='link-container__link' target="_blank" rel="noreferrer">
          {shareLink}
        </a> */}
        <div className="d-flex justify-content-center align-items-center">
          <Button
            className="button-copy"
            onMouseLeave={handleHoverOutCopy}
            onClick={handleCopyText}
          >
            {isCopying && (
              <Icon name="web_icon-check-done" className="mr-2 text-white" />
            )}
            {isCopying ? t('copied') : t('copyLink')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ShareModal
