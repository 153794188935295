/* eslint-disable multiline-ternary */
import { sarosSwapType } from 'common/constants'
import {
  convertWeiToBalance,
  formatNumberBro,
  roundingNumber,
  upperCase
} from 'common/functions'
import { MINT_LP_DEFAULT } from 'common/pool/constants'
import Button from 'components/common/Button'
import InputTokenAmount from 'components/InputTokenAmount'
import useLiquidity from 'hooks/liquidity/useLiquidity'
import { get } from 'lodash'
import { LiquidityContext } from 'context/liquidity/liquidityContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalAddLiquidity from '../../../../pages/NewLiquidityScreen/Components/ModalConfirmLiquidity'

import styles from './style.module.scss'
import useCheckWallet from 'hooks/useCheckWallet'
import { AppContext } from 'context/appContext'
import { NATIVE_SOL } from 'common/solana'
import { useSelector } from 'react-redux'
import PuffLoading from 'components/common/PuffLoading'

const liquidityAmountDefault = {
  token0Amount: '',
  token1Amount: ''
}

function AddLiquidityNew () {
  const { t } = useTranslation()
  const balanceSol = useSelector((state) => state.balanceSol)
  const accountSol = useSelector((state) => state.accountSol)

  const appContext = useContext(AppContext)
  const liquidityContext = useContext(LiquidityContext)
  const {
    initDataLiquidity,
    handlePushStateUrl,
    dataPoolInfo,
    slippageValue,
    liquidityToken,
    setLiquidityToken,
    isLoadingDetailPool,
    isNewPool,
    isShortcut
  } = liquidityContext
  const { listAccountOwner, handleGetAllAccountOwner } = appContext
  const liquidity = useLiquidity({ isFetchListToken: false })
  const { isConnectWallet, handleInstallWallet } = useCheckWallet()

  const [dataPoolInfoLiquidity, setDataPoolInfoLiquidity] = useState(null)

  const [liquidityAmount, setLiquidityAmount] = useState({
    ...liquidityAmountDefault
  })
  const [balanceToken, setBalanceToken] = useState({
    from: 0,
    to: 0
  })
  const [shareOfPool, setShareOfPool] = useState(0)
  const [lpTokenReceive, setLpTokenReceive] = useState(0)
  const [isLoadingButton, setIsLoadingButton] = useState(false)

  const type = dataPoolInfoLiquidity
    ? sarosSwapType.addLiquidiTy
    : sarosSwapType.createPool
  const token0 = get(liquidityToken, 'token0')
  const token1 = get(liquidityToken, 'token1')
  const token0Amount = parseFloat(get(liquidityAmount, 'token0Amount', 0))
  const token1Amount = parseFloat(get(liquidityAmount, 'token1Amount', 0))

  const isCreatePool = isNewPool || type === sarosSwapType.createPool
  const isShowBenefit = token0 && token1
  const rate0 = isCreatePool
    ? token0Amount / token1Amount
    : get(dataPoolInfoLiquidity, 'rate0')
  const rate1 = isCreatePool
    ? 1 / (token0Amount / token1Amount)
    : get(dataPoolInfoLiquidity, 'rate1')

  useEffect(() => {
    setDataPoolInfoLiquidity(dataPoolInfo)
  }, [dataPoolInfo])

  useEffect(() => {
    setLiquidityAmount({ ...liquidityAmountDefault })
  }, [dataPoolInfo, liquidityToken])

  useEffect(() => {
    const { token0, token1 } = liquidityToken
    const { token0Amount, token1Amount } = liquidityAmount
    if (
      token0 &&
      token1 &&
      token0Amount &&
      token1Amount &&
      dataPoolInfoLiquidity
    ) {
      return calculateBenefitPools()
    }
    if (isCreatePool) {
      // calculate share of pool, if is create pool default is 100
      if (token0Amount && token1Amount) {
        setShareOfPool(100)
      } else {
        setShareOfPool(0)
      }
      setLpTokenReceive(convertWeiToBalance(MINT_LP_DEFAULT, 2))
    } else {
      setShareOfPool(0)
    }
  }, [liquidityToken, liquidityAmount, dataPoolInfoLiquidity])

  useEffect(() => {
    handleUpdateBalanceTokenSelected()
  }, [liquidityToken, listAccountOwner, balanceSol])

  const initDataDefault = async () => {
    handleGetAllAccountOwner()
    await initDataLiquidity()
    setLiquidityAmount(liquidityAmountDefault)
  }

  const handleUpdateBalanceTokenSelected = () => {
    if (!accountSol) {
      setBalanceToken({
        from: 0,
        to: 0
      })
      return
    }
    const { token0, token1 } = liquidityToken
    const balance0 = listAccountOwner.find(
      (item) => item.mint === get(token0, 'mintAddress')
    )
    const balance1 = listAccountOwner.find(
      (item) => item.mint === get(token1, 'mintAddress')
    )
    const fromMint = get(token0, 'mintAddress')
    const toMint = get(token1, 'mintAddress')

    const isFromSol = fromMint === NATIVE_SOL.mintAddress
    const isToSol = toMint === NATIVE_SOL.mintAddress
    setBalanceToken({
      from: isFromSol
        ? balanceSol
        : convertWeiToBalance(
          get(balance0, 'amount', 0),
          get(token0, 'decimals')
        ),
      to: isToSol
        ? balanceSol
        : convertWeiToBalance(
          get(balance1, 'amount', 0),
          get(token1, 'decimals')
        )
    })
  }

  const handleReverse = () => {
    const { token0, token1 } = liquidityToken
    const newLiquidityToken = {
      token0: token1,
      token1: token0
    }
    if (!isShortcut) {
      handlePushStateUrl({
        token1Mint: get(newLiquidityToken, 'token1.mintAddress'),
        token0Mint: get(newLiquidityToken, 'token0.mintAddress'),
        type: sarosSwapType.addLiquidiTy
      })
    }
    setLiquidityToken(newLiquidityToken)
  }

  const handleSelectToken = ({ isBase, coinSelected }) => {
    const { token0, token1 } = liquidityToken
    const newLiquidityToken = {
      ...liquidityToken,
      [isBase ? 'token0' : 'token1']: coinSelected
    }
    if (
      get(coinSelected, 'mintAddress') === get(token0, 'mintAddress') ||
      get(coinSelected, 'mintAddress') === get(token1, 'mintAddress')
    ) {
      return handleReverse()
    }

    if (!isShortcut) {
      handlePushStateUrl({
        token1Mint: get(newLiquidityToken, 'token1.mintAddress'),
        token0Mint: get(newLiquidityToken, 'token0.mintAddress'),
        type: sarosSwapType.addLiquidiTy
      })
    }
    setLiquidityToken(newLiquidityToken)
  }

  const handleChangeAmount = ({ value, isBase, tokenInfo }) => {
    if (dataPoolInfoLiquidity) {
      const amountOut = liquidity.calculateAmount({
        amount: value,
        mintAddress: get(tokenInfo, 'mintAddress'),
        poolInfo: dataPoolInfo
      })
      return setLiquidityAmount({
        [isBase ? 'token0Amount' : 'token1Amount']: value,
        [isBase ? 'token1Amount' : 'token0Amount']: roundingNumber(
          amountOut,
          4
        )
      })
    }

    return setLiquidityAmount({
      ...liquidityAmount,
      [isBase ? 'token0Amount' : 'token1Amount']: value
    })
  }

  const calculateBenefitPools = () => {
    const { token0Amount } = liquidityAmount
    const { token0 } = liquidityToken
    const decimalsLp = get(dataPoolInfoLiquidity, 'lpInfo.decimals', '')
    const lpTokenSupply = parseFloat(
      convertWeiToBalance(
        get(dataPoolInfoLiquidity, 'lpInfo.supply', '').toString(),
        decimalsLp
      )
    )
    const amountLpTokenReceive = liquidity.calculateLpReceive({
      poolInfo: dataPoolInfoLiquidity,
      amount: token0Amount,
      sourceToken: token0
    })

    const shareOfPool =
      (parseFloat(amountLpTokenReceive) /
        (parseFloat(amountLpTokenReceive) + lpTokenSupply)) *
      100

    setShareOfPool(shareOfPool)
    setLpTokenReceive(amountLpTokenReceive)
  }

  const handleSubmit = () => {
    if (!isConnectWallet) {
      return handleInstallWallet()
    }
    const { token0Amount, token1Amount } = liquidityAmount
    const rate0 = isCreatePool
      ? token1Amount / token0Amount
      : get(dataPoolInfoLiquidity, 'rate0', '-')
    const rate1 = isCreatePool
      ? 1 / (token1Amount / token0Amount)
      : get(dataPoolInfoLiquidity, 'rate1', '-')
    window.openModal({
      noIcon: true,
      className: 'modal-confirm-add-liquidity',
      noPadding: true,
      preventClose: true,
      content: (
        <ModalAddLiquidity
          setIsLoadingButton={setIsLoadingButton}
          slippage={slippageValue}
          initDataDefault={initDataDefault}
          dataPoolInfoLiquidity={dataPoolInfoLiquidity}
          liquidityToken={liquidityToken}
          liquidityAmount={liquidityAmount}
          rate0={rate0}
          rate1={rate1}
          isCreatePool={isCreatePool}
          lpAmountReceive={lpTokenReceive}
          percentShare={shareOfPool}
          type={type}
        />
      )
    })
  }

  const typeButton = useMemo(() => {
    const typeButton = {
      text: 'supply',
      isDisabled: false
    }
    const { token0, token1 } = liquidityToken
    const { token0Amount, token1Amount } = liquidityAmount
    const balance0 = parseFloat(get(balanceToken, 'from', 0))
    const balance1 = parseFloat(get(balanceToken, 'to', 0))
    if (!isConnectWallet) {
      return {
        text: 'connectWallet',
        isDisabled: false
      }
    }

    if (!token0 || !token1) {
      return {
        text: 'selectToken',
        isDisabled: true
      }
    }

    if (!parseFloat(token0Amount) || !parseFloat(token1Amount)) {
      return {
        text: 'inputAmount',
        isDisabled: true
      }
    }

    if (
      parseFloat(token0Amount) > parseFloat(balance0) ||
      parseFloat(token1Amount) > parseFloat(balance1)
    ) {
      return {
        text: 'gasSolNotEnough',
        isDisabled: true
      }
    }
    return typeButton
  }, [liquidityAmount, liquidityToken, isConnectWallet, balanceToken])
  return (
    <div className={styles['add-liquidity-new']}>
      {isLoadingDetailPool ? (
        <div className="add-liquidity-new__loading">
          <PuffLoading isCenter />
        </div>
      ) : (
        <>
          <div className="liquidity-form-wrapper__content mb-4">
            <InputTokenAmount
              valueInput={get(liquidityAmount, 'token0Amount', '')}
              onChangeToken={handleSelectToken}
              isDisabledInput={
                !get(liquidityToken, 'token0') ||
                isLoadingButton ||
                (!isNewPool && !dataPoolInfoLiquidity)
              }
              isBase
              // tokenList={listToken}
              coinSelected={get(liquidityToken, 'token0')}
              onchangeInput={handleChangeAmount}
              coinFilter={get(liquidityToken, 'token1')}
              balance={get(balanceToken, 'from', 0)}
            />

            <div className="plus-icon color-grey text-xl">+</div>

            <InputTokenAmount
              isDisabledInput={
                !get(liquidityToken, 'token1') ||
                isLoadingButton ||
                (!isNewPool && !dataPoolInfoLiquidity)
              }
              valueInput={get(liquidityAmount, 'token1Amount', '')}
              isBase={false}
              onChangeToken={handleSelectToken}
              // tokenList={listToken}
              coinSelected={get(liquidityToken, 'token1')}
              onchangeInput={handleChangeAmount}
              coinFilter={get(liquidityToken, 'token0')}
              balance={get(balanceToken, 'to', 0)}
            />
          </div>
          {isShowBenefit && (
            <div className="liquidity-form-wrapper__price-info ">
              <div className="price-info-title text-sm color-grey">
                {t('priceAndPool')}
              </div>

              <div className="price-info-content">
                <div className="info-item">
                  <div className="info-item__number">
                    {formatNumberBro({
                      number: rate1 || '-',
                      mantissa: 3
                    })}
                  </div>

                  <div className="info-item__name text-xs color-grey">
                    {upperCase(get(token0, 'symbol'))} Per{' '}
                    {upperCase(get(token1, 'symbol'))}
                  </div>
                </div>

                <div className="info-item">
                  <div className="info-item__number">
                    {formatNumberBro({
                      number: rate0 || '-',
                      mantissa: 3
                    })}
                  </div>

                  <div className="info-item__name text-xs color-grey">
                    {upperCase(get(token1, 'symbol'))} Per{' '}
                    {upperCase(get(token0, 'symbol'))}
                  </div>
                </div>

                <div className="info-item">
                  <div className="info-item__number">
                    {formatNumberBro({
                      number: shareOfPool,
                      mantissa: 2
                    })}
                    %
                  </div>

                  <div className="info-item__name text-xs color-grey">
                    {t('shareOfPool')}
                  </div>
                </div>
              </div>
            </div>
          )}

          <Button
            isLoading={isLoadingButton}
            isDisable={typeButton.isDisabled || isLoadingButton}
            isFullWidth
            onClick={handleSubmit}
          >
            {t(typeButton.text)}
          </Button>
        </>
      )}
    </div>
  )
}

export default AddLiquidityNew
